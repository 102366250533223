import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
-webkit-tap-highlight-color: transparent;
outline: none;
}

.darkSelection{
  *{
    &::-moz-selection { color: black; background: white;}
&::selection { color: black; background: white;}
  }
  
}

.lightSelection{
  *{
    &::-moz-selection { color: white; background: #0E0F11;}
&::selection { color: white; background: #0E0F11;}
  }
  
}


body {
  font-family: corporate-s,sans-serif;
  font-style: normal;
  background: black;
  width: 100vw;
  scroll-behavior: smooth;
  overflow-x: hidden;
  @media (max-width: 280px){
    overflow-x: scroll;
  }
  --main-blue-color: #2C72FC;
  --main-blue-color-hover:#2665e2;
  --main-dark-color: #0E0F11;
  --main-light-color: #fff;
  --main-gray-color: #949494;
  --main-darkgray-color: #5f5f5f;
  --main-red-color: #FF3B3B;
  --main-red-color-hover: #df3131;
}


/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #7a7a7a rgba(0,0,0,0);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: rgba(0,0,0,0); 
}

*::-webkit-scrollbar-thumb {
  background-color: #7a7a7a;
  border-radius: 20px;
  &:hover{
    background-color: #494949; 
  }
}





// DEFAULT STYLING

h1,h2,h3,h4,h5,h6,a,button,input, p, li, span, div {
  font-family: corporate-s,sans-serif;
  font-style: normal;
}

h1{
// EXTRA-BOLD
font-weight: 800;
}

h2, button, input{
// BOLD
font-weight: 700;
}

h3{
  // DEMI
  font-weight: 600;
}

h4{
// MEDIUM
font-weight: 500;
}

p, a{
  // MEDIUM
  font-weight: 500;
}

button {
  font-size: 1.2rem;
  border-radius: 8px;
  padding: 0.5rem;
  cursor: pointer;
  @media (max-width: 50rem) {
	font-size: 1.1rem;
  }
}

img{
    pointer-events: none;
}

.dark-bg{
  background:  var(--main-dark-color);
}

.bright-bg {
  background:  var(--main-light-color);
}

.red-bg {
  background: var(--main-red-color);
}

.blue-bg {
  background:  var(--main-blue-color);
}

.red-color {
  color: var(--main-red-color);
}

.blue-color {
  color: var(--main-blue-color);
}

.red-btn {
  color: var(--main-light-color);
  background: var(--main-red-color);
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  &:active{
    background: var(--main-red-color-hover);
  }
}

.blue-btn {
  color: white;
  background: var(--main-blue-color);
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  &:active{
    background: var(--main-blue-color-hover);
  }
}

.light-btn {
  color: var(--main-dark-color);
  background: var(--main-light-color);
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  &:active{
    background: var(--main-light-color);
  }
}

.dark-btn {
  color: var(--main-light-color);
  background: var(--main-dark-color);
  border: none;
  &:active{
    background: var(--main-dark-color);
  }
}

.light-online {
    color: var(--main-dark-color);
    background: #dbdbdb;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    &:active {
      background: #b9b9b9;
    }
  }
  .dark-online {
    color: white;
    background: #202327;
    border: none;
    &:active {
      background: #2e3338;
    }
  }



/* KEYFRAME ANIMATIONS */
@keyframes pageSlideInTransition {
  from{
    opacity: 0;
    /* transform: translatex(0.5rem); */
  } to {
    opacity: 1;
    /* transform: translateY(0rem); */
  }
}

`;

export default GlobalStyle;
