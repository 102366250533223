import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { InputOutput } from "../components/GuiElements";

const Winner = ({
  setCurrentPlayer,
  currentPlayer,
  setCurrentGame,
  currentGame,
  setGameSettings,
  gameSettings,
  setSettings,
  settings,
  allPlayers,
  setAllPlayers,
  spielstand,
  setSpielstand,
  gesamtdauer,
  socket,
  userId,
  online,
}) => {
  const history = useHistory();
  const [winning, setWinning] = useState([]);
  useEffect(() => {
    const highest = Math.max(...spielstand);
    let wins = [];
    spielstand.forEach(function (element, i) {
      if (element === highest) {
        wins.push(allPlayers[i]?.name);
      }
    });
    setWinning([...wins]);
    if (online) {
      socket?.emit("send-data", userId, {
        event: "winner",
        sender: "game",
        room: userId,
        payload: {
          winning: [...wins],
          gesamtdauer: gesamtdauer,
          spielstand: spielstand,
        },
      });
    }
    if (spielstand?.length === 0) {
      history.push("/");
    }
  }, [spielstand, allPlayers, gesamtdauer, history, online, socket, userId]);

  return (
    <Card
      style={{
        background: !settings.darkmode
          ? "var(--main-dark-color)"
          : "var(--main-light-color)",
      }}
    >
      <span
        style={{
          color: !settings.darkmode
            ? "var(--main-light-color)"
            : "var(--main-dark-color)",
        }}
        onClick={() => {
          history.push("/");
        }}
        className="back"
      >
        {"<"}&nbsp;&nbsp;&nbsp;
      </span>
      <h1
        className="main-title"
        style={{ color: !settings.darkmode ? "#ffffff" : "#000000" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50.846"
          height="50.846"
          viewBox="0 0 50.846 50.846"
        >
          <path
            id="Vereinigungsmenge_50"
            data-name="Vereinigungsmenge 50"
            d="M2366-4124.886a25.218,25.218,0,0,1,2.483-10.66l4.571,4.568a19.248,19.248,0,0,0-1,6.092,19.387,19.387,0,0,0,19.364,19.366,19.389,19.389,0,0,0,19.366-19.366,19.387,19.387,0,0,0-19.366-19.364,19.233,19.233,0,0,0-6.09,1l-4.57-4.569a25.183,25.183,0,0,1,10.66-2.484,25.521,25.521,0,0,1,25.423,25.422,25.522,25.522,0,0,1-25.423,25.423A25.521,25.521,0,0,1,2366-4124.886Zm12.016,0a13.244,13.244,0,0,1,.824-4.469h1.795l3.444,3.443a7.223,7.223,0,0,0-.1,1.025,7.457,7.457,0,0,0,7.448,7.448,7.458,7.458,0,0,0,7.449-7.448,7.458,7.458,0,0,0-7.449-7.448,7.252,7.252,0,0,0-1.025.1l-3.444-3.444v-1.795a13.267,13.267,0,0,1,4.469-.823,13.42,13.42,0,0,1,13.406,13.406,13.421,13.421,0,0,1-13.406,13.406A13.422,13.422,0,0,1,2378.017-4124.886Zm12.382,1.073c-.008-.009-.02-.01-.029-.02l-8.5-8.5h-5.341a1.492,1.492,0,0,1-1.054-.437l-9.036-9.037a1.49,1.49,0,0,1-.324-1.624,1.493,1.493,0,0,1,1.377-.919h4.567v-4.469a1.489,1.489,0,0,1,.921-1.376,1.489,1.489,0,0,1,1.622.323l8.938,9.037a1.489,1.489,0,0,1,.437,1.053v5.341l8.5,8.5c.008.008.01.02.019.028a1.469,1.469,0,0,1-.048,2.1,1.477,1.477,0,0,1-1.024.417A1.481,1.481,0,0,1,2390.4-4123.813Z"
            transform="translate(-2366 4150.309)"
            fill={!settings.darkmode ? "#ffffff" : "#000000"}
          />
        </svg>
        {winning.length > 2 ? winning.join(", ") : winning.join(" und ")}{" "}
        {winning.length > 1 ? "haben" : "hat"} gewonnen!
      </h1>

      <div className="info">
        <InputOutput
          title={`Dauer`}
          align={"center"}
          width={"100%"}
          placeholder={"Dauer"}
          state={gesamtdauer}
          setState={setSpielstand}
          key={"1"}
          darkmode={!settings.darkmode}
        />

        <InputOutput
          title={`Spielstand`}
          align={"center"}
          width={"100%"}
          placeholder={"Spielstand"}
          state={spielstand.join(":")}
          setState={setSpielstand}
          key={"2"}
          darkmode={!settings.darkmode}
        />
      </div>

      <button
        onClick={() => {
          history.push("/statistic");
        }}
        className={!settings.darkmode ? "light-btn" : "dark-btn"}
      >
        Statistiken
      </button>

      <button
        onClick={() => {
          history.push("/newgame");
          if (online) {
            socket?.volatile.emit("send-data", userId, {
              event: "stop",
              sender: "game",
              room: userId,
            });
          }
        }}
        className="blue-btn main-newgame"
      >
        Neues Spiel
      </button>
    </Card>
  );
};

const Card = styled.div`
  animation: pageSlideInTransition 0.2s forwards;
  width: 100%;
  height: 100vh;
  min-height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .back {
    position: absolute;
    top: 0.5rem;
    left: 2rem;
    font-size: 1.5rem;
    font-weight: 700;
    cursor: pointer;
  }
  .info {
    width: 22rem;
    max-width: calc(100% - 1rem);
    display: flex;
    div {
      &:first-child {
        margin-right: 0.5rem;
      }
    }
  }

  .light-btn,
  .dark-btn {
    width: 22rem;
    margin-bottom: 1rem;
    max-width: calc(100% - 1rem);
  }

  .main-title {
    font-size: 1.5rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    margin-bottom: 2rem;
    text-align: center;
    svg {
      width: 3rem;
      height: 3rem;
      margin-bottom: 1rem;
    }
  }
  .main-newgame,
  .main-player {
    width: 22rem;
  }
  .main-player {
    margin-bottom: 2rem;
  }
  .stat-dark-btn {
    color: black;
    background: #ffffff;
    border: 2px solid #ffffff;
    &:active {
      background: #eeeeee;
    }
  }
  .stat-light-btn {
    color: white;
    background: black;
    border: 2px solid black;
    overflow: hidden;
    text-overflow: ellipsis;
    &:active {
      background: #131313;
    }
  }
  @media (max-width: 22rem) {
    @media (max-width: 15rem) {
      .main-title {
        flex-direction: column;
      }
    }

    .main-newgame,
    .main-player {
      width: calc(100% - 1rem);
    }
  }
`;

export default Winner;
