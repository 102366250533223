import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { InputOutput } from "../components/GuiElements";
import { useHistory } from "react-router-dom";

const OnlineRoom = ({ settings, setRoomId }) => {
  const history = useHistory();
  const [input, setInput] = useState("");

  useEffect(() => {
    function getParameterByName(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
    let id = getParameterByName("id", history.location.search);
    if (id?.length === 8 && /^[\w.-]+$/g.test(id)) {
      setRoomId(id?.toUpperCase());
    } else {
      history.push("/online");
    }
  }, []);

  return (
    <Card
      style={{
        background: !settings.darkmode
          ? "var(--main-dark-color)"
          : "var(--main-light-color)",
      }}
    >
      <span
        style={{
          color: !settings.darkmode
            ? "var(--main-light-color)"
            : "var(--main-dark-color)",
        }}
        onClick={() => {
          history.push("/");
        }}
        className="back"
      >
        {"<"}&nbsp;&nbsp;&nbsp;
      </span>
      <h1
        className="players-title"
        style={{ color: !settings.darkmode ? "#fff" : "#000" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill={!settings.darkmode ? "#fff" : "#000"}
            d="M6.043 19.496l-1.482 1.505c-2.791-2.201-4.561-5.413-4.561-9.001s1.77-6.8 4.561-9l1.482 1.504c-2.326 1.835-3.804 4.512-3.804 7.496s1.478 5.661 3.804 7.496zm.675-7.496c0-1.791.887-3.397 2.282-4.498l-1.481-1.502c-1.86 1.467-3.04 3.608-3.04 6s1.18 4.533 3.04 6l1.481-1.502c-1.396-1.101-2.282-2.707-2.282-4.498zm15.043 0c0-2.984-1.478-5.661-3.804-7.496l1.482-1.504c2.791 2.2 4.561 5.412 4.561 9s-1.77 6.8-4.561 9.001l-1.482-1.505c2.326-1.835 3.804-4.512 3.804-7.496zm-6.761 4.498l1.481 1.502c1.86-1.467 3.04-3.608 3.04-6s-1.18-4.533-3.04-6l-1.481 1.502c1.396 1.101 2.282 2.707 2.282 4.498s-.886 3.397-2.282 4.498zm-3-7.498c-1.656 0-3 1.343-3 3s1.344 3 3 3 3-1.343 3-3-1.344-3-3-3z"
          />
        </svg>
      </h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          input &&
            input
              ?.replace("https://stahlspicker.tnstudios.de/online?id=", "")
              ?.toUpperCase()
              ?.replaceAll(/[^a-zA-Z0-9.]/g, "")
              ?.substr(0, 8).length === 8 &&
            setRoomId(
              input
                ?.replace("https://stahlspicker.tnstudios.de/online?id=", "")
                ?.toUpperCase()
                ?.replaceAll(/[^a-zA-Z0-9.]/g, "")
                ?.substr(0, 8)
            );
        }}
        className="selectors"
      >
        <InputOutput
          input
          title={`ID`}
          align={"center"}
          width={"100%"}
          placeholder={"ID eingeben"}
          state={input
            ?.replace("https://stahlspicker.tnstudios.de/online?id=", "")
            ?.toUpperCase()
            ?.replaceAll(/[^a-zA-Z0-9.]/g, "")
            ?.substr(0, 8)}
          setState={setInput}
          darkmode={!settings.darkmode}
        />
        <button type="submit" className="blue-btn">
          Verbinden
        </button>
      </form>
    </Card>
  );
};

const Card = styled.div`
  width: 100%;
  height: 100vh;
  min-width: 13rem;
  min-height: 30rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: pageSlideInTransition 0.2s forwards;
  .back {
    position: absolute;
    top: 0.5rem;
    left: 2rem;
    font-size: 1.5rem;
    font-weight: 700;
    cursor: pointer;
  }
  .players-title {
    font-size: 1.5rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    svg {
      width: 2rem;
      height: 2rem;
      margin-right: 0.5rem;
    }
  }
  .selectors {
    width: 22rem;
    margin-bottom: 0.5rem;
    button {
      width: 100%;
    }
  }
`;

export default OnlineRoom;
