import React, { useState, useEffect } from "react";
import styled from "styled-components";

const CookieBanner = ({ darkmode }) => {
  const [vis, setVis] = useState(false);

  useEffect(() => {
    const localAccept = JSON.parse(
      localStorage.getItem("stahlspicker-cookie-accept")
    );
    if (localAccept === null) {
      setVis(true);
    } else {
      setVis(JSON.parse(localStorage.getItem("stahlspicker-cookie-accept")));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("stahlspicker-cookie-accept", vis);
  }, [vis]);

  return (
    <StyledCookieBanner
      style={{ transform: vis ? "translateY(0)" : "translateY(100%)" }}
    >
      <p>
        Wir verwenden Cookies und ähnliche Technologien, um Dienste bzw.
        Funktionen auf unserer Website zu gewährleisten und um zu verstehen, wie
        Sie diese nutzen. Indem Sie auf "Verstanden" klicken, stimmen Sie deren
        Verwendung für Marketing- und Analysezwecke zu.{" "}
        <a
          rel="noreferrer"
          href="https://stream.tnstudios.de/datenschutz"
          target="_blank"
          title="Datenschutzerklärung"
        >
          Zur Datenschutzerklärung
        </a>
      </p>
      <button
        onClick={() => {
          setVis(false);
        }}
        className="blue-btn"
      >
        Verstanden
      </button>
    </StyledCookieBanner>
  );
};

const StyledCookieBanner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  min-height: 4rem;
  background: #090a0b;
  z-index: 1000;
  display: flex;
  color: white;
  font-weight: 300;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  transition: transform 0.5s ease;
  a {
    color: #b4b4b4;
  }
  p {
    padding-right: 1rem;
    font-size: 1rem;
    color: #b4b4b4;
  }
  button {
    padding: 0.4rem 0.5rem;
    min-width: 8rem;
    font-size: 1.1rem;
    border-radius: 0.2rem;
  }
  @media (max-width: 45rem) {
    flex-direction: column;
    button {
      margin-top: 1rem;
    }
    p {
      text-align: center;
    }
  }
`;

export default CookieBanner;
