import React, { useEffect, useState } from "react";
import { CheckoutDisplay } from "../components/GuiElements";
import styled from "styled-components";

const Counter = ({
  darkmode,
  gameSettings,
  currentInput,
  currentPlayer,
  playerNr,
  allPlayers,
  gameData,
  statistics,
  setStatistics,
  currentWinner,
  secondWinner,
}) => {
  const playername = allPlayers[playerNr - 1].name;
  const active = currentPlayer === playerNr;
  const start = false;
  const score = gameData?.score;
  const [tempCalc, setTempCalc] = useState(0);
  const amount = currentPlayer === playerNr ? gameData?.amount + currentInput.length : gameData?.amount;
  const average = Math.round(Math.abs(((score - tempCalc - gameSettings?.startzahl) / amount) * 3) * 100) / 100;
  const lastInput = currentInput.length > 0 ? currentInput.length - 1 : 0;
  const lastDouble =
    currentInput?.[lastInput]?.includes("D") ||
    currentInput?.[lastInput]?.includes("BULL'S EYE") ||
    (currentInput.length >= 2 && currentInput?.[lastInput] === "-" && currentInput?.[lastInput - 1]?.includes("D")) ||
    (currentInput.length === 3 &&
      currentInput?.[lastInput] === "-" &&
      currentInput?.[lastInput - 1] === "-" &&
      currentInput?.[lastInput - 2]?.includes("D"));

  useEffect(() => {
    let count = 0;
    if (currentInput.length > 0 && currentPlayer === playerNr) {
      currentInput.forEach((onescore) => {
        let countedscore;
        if (onescore.includes("D")) {
          countedscore = 2 * parseInt(onescore.replace("D", ""));
        } else if (onescore.includes("T")) {
          countedscore = 3 * parseInt(onescore.replace("T", ""));
        } else if (onescore === "BULL") {
          countedscore = 25;
        } else if (onescore === "BULL'S EYE") {
          countedscore = 50;
        } else if (onescore === "-") {
          countedscore = 0;
        } else {
          countedscore = parseInt(onescore);
        }
        count = count + countedscore;
      });
    } else {
      setTempCalc(0);
      count = 0;
    }
    setTempCalc(count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInput]);

  useEffect(() => {
    if (average && active && statistics) {
      let stats = [...statistics];
      average !== Infinity && stats[playerNr - 1].threeDartAvg.push(average);
      setStatistics(stats);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPlayers]);

  return (
    <StyledCounter
      style={{
        borderColor: active ? "var(--main-blue-color)" : darkmode ? "white" : "black",
        flex: `1 0 calc(${100 / allPlayers.length}% - 1rem)`,
        maxWidth: `calc(${100 / allPlayers.length}% - 0.5rem)`,
      }}
    >
      <span
        className={darkmode ? "playername dark-bg" : "playername bright-bg"}
        style={{
          color: darkmode ? "white" : "black",
          fontSize: "1.2rem",
          top: "-1rem",
        }}
      >
        {start && (
          <span
            style={{
              color: "var(--main-blue-color)",
            }}
          >
            •
          </span>
        )}{" "}
        {playername}
      </span>
      <CheckoutDisplay
        score={score - tempCalc < 0 || score - tempCalc === 1 ? score : score - tempCalc}
        darkmode={darkmode}
        players={allPlayers?.length}
      />

      {score - tempCalc !== 0 ? (
        <h2
          className={allPlayers?.length > 2 ? "countmany" : "count"}
          style={{
            color: darkmode ? "white" : "black",
          }}
        >
          {" "}
          {score - tempCalc < 0 || score - tempCalc === 1 ? score : score - tempCalc}
        </h2>
      ) : lastDouble || currentWinner === playerNr || secondWinner === playerNr ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="72.154"
          height="72.155"
          viewBox="0 0 72.154 72.155"
          className="lastfinish"
        >
          <path
            id="Vereinigungsmenge_53"
            data-name="Vereinigungsmenge 53"
            d="M-6643.5-2024.883a35.74,35.74,0,0,1,3.527-15.128l6.484,6.484a27.32,27.32,0,0,0-1.414,8.643,27.512,27.512,0,0,0,27.479,27.481,27.513,27.513,0,0,0,27.481-27.481,27.512,27.512,0,0,0-27.479-27.479,27.32,27.32,0,0,0-8.645,1.414l-6.484-6.486a35.77,35.77,0,0,1,15.128-3.525,36.217,36.217,0,0,1,36.078,36.076,36.218,36.218,0,0,1-36.078,36.078A36.218,36.218,0,0,1-6643.5-2024.883Zm17.052,0a18.828,18.828,0,0,1,1.168-6.343h2.548l4.885,4.889a10.491,10.491,0,0,0-.146,1.454,10.583,10.583,0,0,0,10.569,10.569,10.582,10.582,0,0,0,10.571-10.569,10.582,10.582,0,0,0-10.571-10.569,10.2,10.2,0,0,0-1.454.148l-4.887-4.889v-2.546a18.764,18.764,0,0,1,6.341-1.169,19.046,19.046,0,0,1,19.026,19.024,19.048,19.048,0,0,1-19.026,19.026A19.046,19.046,0,0,1-6626.447-2024.883Zm17.572,1.522c-.013-.012-.028-.017-.042-.028l-12.063-12.063h-7.581a2.113,2.113,0,0,1-1.494-.62l-12.823-12.824a2.108,2.108,0,0,1-.458-2.3,2.107,2.107,0,0,1,1.952-1.306h6.483v-6.341a2.112,2.112,0,0,1,1.305-1.954,2.112,2.112,0,0,1,2.3.458l12.684,12.823a2.112,2.112,0,0,1,.619,1.5v7.58l12.065,12.065c.011.012.015.028.026.04a2.083,2.083,0,0,1-.068,2.976,2.092,2.092,0,0,1-1.452.592A2.1,2.1,0,0,1-6608.875-2023.361Z"
            transform="translate(6643.5 2060.96)"
            fill={
              currentWinner && playerNr !== currentWinner
                ? secondWinner && playerNr !== secondWinner
                  ? "var(--main-darkgray-color)"
                  : "var(--main-gray-color)"
                : "var(--main-blue-color)"
            }
          />
        </svg>
      ) : (
        <h2
          className={allPlayers?.length > 2 ? "countmany" : "count"}
          style={{
            color: darkmode ? "white" : "black",
          }}
        >
          {" "}
          {score}
        </h2>
      )}

      <p className="statdata" style={{ color: darkmode ? "white" : "black" }}>
        Ø {average || 0}
      </p>
      <p className="statdata" style={{ color: darkmode ? "white" : "black" }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="28.815" height="28.815" viewBox="0 0 28.815 28.815">
          <path
            id="Pfeil"
            d="M-3563-6212.47l4.686-4.687a2.084,2.084,0,0,1-.135-.737,2.055,2.055,0,0,1,.6-1.461l.6-.6,2.922,2.922-.6.6a2.053,2.053,0,0,1-1.461.6,2.076,2.076,0,0,1-.738-.135l-4.687,4.686Zm6.952-8.684,2.734-2.736,2.924,2.922-2.736,2.736Zm3.932-3.929.938-.939a2.059,2.059,0,0,1,1.463-.606,2.055,2.055,0,0,1,1.461.606,2.058,2.058,0,0,1,.6,1.462,2.058,2.058,0,0,1-.6,1.461l-.94.938Zm5.055-2.134a3.757,3.757,0,0,0-1.073-.75l10.586-10.586a1.292,1.292,0,0,1,1.824,0,1.292,1.292,0,0,1,0,1.824l-10.585,10.587A3.719,3.719,0,0,0-3547.06-6227.217Zm5.6-1.383,6.253-6.253,1.024,3.331-3.948,3.946Zm-5.239-7.545,3.947-3.947,3.331,1.024-6.253,6.253Z"
            transform="translate(3563 6240.092)"
            fill={darkmode ? "#fff" : "#000"}
          />
        </svg>
        {amount}
      </p>
    </StyledCounter>
  );
};

const StyledCounter = styled.div`
  border: 2px solid;
  border-radius: 0.5rem;
  margin-right: 1rem;
  &:last-child {
    margin-right: 0rem;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.6rem;
  @media (max-width: 45rem) {
    padding: 0.3rem 0.3rem 0.1rem 0.3rem;
  }
  position: relative;
  .playername {
    position: absolute;
    padding: 0 0.6rem;
    white-space: nowrap;
    @media (max-width: 45rem) {
      padding: 0 0.3rem !important;
      font-size: 1rem !important;
    }
    @media (max-width: 25rem) {
      padding: 0 0.3rem !important;
      font-size: 4vw !important;
      top: -4vw !important;
    }
  }
  .count {
    font-weight: 800;
    font-size: 11vw;
    line-height: 8vw;
    margin-bottom: 0.5rem;
    @media (min-width: 45.1rem) {
      font-size: 5.5rem !important;
      line-height: 5.5rem !important;
    }
  }
  .countmany {
    font-weight: 800;
    line-height: 8vw;
    font-size: 8vw;
    margin-bottom: 0.8rem;
    @media (min-width: 70rem) {
      font-size: 5.5rem !important;
      line-height: 5.5rem !important;
    }
  }
  .lastfinish {
    width: 3rem;
    height: 3rem;
    margin: 1.5rem 0;
    @media (max-width: 40rem) {
      width: 2.3rem;
      height: 2.3rem;
      margin: 0.95rem 0;
    }
  }
  .statdata {
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.2rem;
    @media (max-width: 45rem) {
      font-size: 1rem;
      height: 1.3rem;
    }
    svg {
      height: 0.8rem;
      width: 0.8rem;
      margin-right: 0.2rem;
      margin-bottom: -0.2rem;
      @media (max-width: 40rem) {
        height: 0.8rem;
        width: 0.8rem;
      }
    }
  }
`;

export default Counter;
