import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { InputOutput } from "../components/GuiElements";
import { useHistory } from "react-router-dom";
import Counter from "../components/Counter";
import timer from "../util/Timer";

const Game = ({
  setCurrentPlayer,
  currentPlayer,
  setCurrentGame,
  currentGame,
  gameSettings,
  settings,
  allPlayers,
  setAllPlayers,
  spielstand,
  setSpielstand,
  setGesamtdauer,
  online,
  userId,
  socket,
  statistics,
  setStatistics,
}) => {
  const history = useHistory();
  const [spielstandLegs, setSpielstandLegs] = useState([]); // SCORE
  const [dauer, setDauer] = useState("00:00"); // GAME TIME
  const [currentInput, setCurrentInput] = useState([]); // CURRENT SCORE INPUT
  const [viewkey, setViewkey] = useState(false);
  const [socketTrigger, setSocketTrigger] = useState(false);
  const [currentWinner, setCurrentWinner] = useState(null);
  const [secondWinner, setSecondWinner] = useState(null);
  const [socketInput, setSocketInput] = useState({
    value: null,
    trigger: true,
  });
  useEffect(() => {
    let stand = [];
    let stats = [];
    allPlayers.forEach((player) => {
      stand.push(0);
      stats.push({
        name: player.name, //
        place: null,
        wonLegs: 0, //
        wonSets: 0, //
        lostLegs: 0, //
        lostSets: 0, //
        threeDartAvg: [],
        throws: 0, //
        accuracy: 0,
        over60: 0,
        over80: 0,
        over100: 0,
        over120: 0,
        over140: 0,
        over180: 0,
        doublequote: 0,
        highFinishes: 0,
        highestFinish: 0,
        highestFinishAccuracy: 0,
      });
    });
    setStatistics(stats);
    setSpielstand(stand);
    setSpielstandLegs(stand);
    setCurrentWinner(null);
    setSecondWinner(null);
    timer("pause", setDauer);
    timer("reset", setDauer);
    timer("start", setDauer);
    if (Object.keys(gameSettings).length === 0) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // KEYBOARD INPUTS
  const [inputs] = useState([
    "DOUBLE",
    "TRIPLE",
    "DANEBEN",
    "BESTÄTIGEN",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "BULL",
  ]);

  const [multiplier, setMultiplier] = useState(1); // SINGLE, DOUBLE, TRIPLE
  const [correction, setCorrection] = useState(null); // CORRECT CURRENT INPUT

  // CORRENT CURRENT INPUT
  const correctHandler = (e) => {
    e.preventDefault();
    setMultiplier(1);
    const index = parseInt(e.target.getAttribute("index")); // WELCHE ZAHL SOLL GEÄNDERT WERDEN
    if (correction - 1 === index) {
      setCorrection(null); // DISABLE CORRECTION
    } else {
      setCorrection(index + 1); // ENABLE CORRECTION ON CRNT INPUT
      settings?.correction && currentInput?.[index]?.includes("D") && setMultiplier(2);
      settings?.correction && currentInput?.[index] === "BULL'S EYE" && setMultiplier(2);
      settings?.correction && currentInput?.[index]?.includes("T") && setMultiplier(3);
    }
  };

  // KEYBOARD INPUT HANDLING
  const keyboardHandler = (e) => {
    const buttonInputKey = e.target.innerText; // CLICKED BUTTON VALUE
    let count = 0; // START COUNTING VALUE
    // COUNT VALUES
    currentInput.forEach((onescore) => {
      // FOR EACH INPUT CALC THE VALUE AND SET THE COUNTED SCORE
      let countedscore;
      if (onescore.includes("D")) {
        // DOUBLE
        countedscore = 2 * parseInt(onescore.replace("D", ""));
      } else if (onescore.includes("T")) {
        //TRIPLE
        countedscore = 3 * parseInt(onescore.replace("T", ""));
      } else if (onescore === "BULL") {
        // BULL
        countedscore = 25;
      } else if (onescore === "BULL'S EYE") {
        // BULL'S EYE
        countedscore = 50;
      } else if (onescore === "-") {
        // NO SCORE
        countedscore = 0;
      } else {
        // STANDARD SCORING VALUE
        countedscore = parseInt(onescore);
      }
      // ADD TO CRNT COUNT
      count = count + countedscore;
    });

    switch (buttonInputKey) {
      case "BULL":
      case "BULLSEYE":
        if (correction) {
          // CORRECT CRNT INPUT
          const changingArray = [...currentInput];
          changingArray[correction - 1] = multiplier === 2 ? "BULL'S EYE" : "BULL";
          setCurrentInput([...changingArray]);
        } else {
          if (multiplier === 2 || buttonInputKey === "BULLSEYE") {
            const previousScore = gameData[currentPlayer - 1].score - count;
            const currentScore = gameData[currentPlayer - 1].score - count - multiplier * buttonInputKey;
            currentInput.length < 3 &&
              (previousScore <= 1 ? currentScore >= 1 : true) &&
              settings?.correction &&
              setCurrentInput([...currentInput, "BULL'S EYE"]);
          } else {
            const previousScore = gameData[currentPlayer - 1].score - count;
            const currentScore = gameData[currentPlayer - 1].score - count - multiplier * buttonInputKey;
            currentInput.length < 3 &&
              (previousScore <= 1 ? currentScore >= 1 : true) &&
              settings?.correction &&
              setCurrentInput([...currentInput, "BULL"]);
          }
        }
        setCorrection(null);

        setMultiplier(1);
        break;
      case "DOUBLE":
        if (multiplier !== 2) {
          setMultiplier(2);
        } else {
          setMultiplier(1);
        }
        if (correction && settings?.correction) {
          const changingArray = [...currentInput];
          if (changingArray[correction - 1] === "BULL") {
            changingArray[correction - 1] = "BULL'S EYE";
          } else if (changingArray[correction - 1] === "BULL'S EYE") {
            changingArray[correction - 1] = "BULL";
          } else {
            if (changingArray[correction - 1].includes("T")) {
              changingArray[correction - 1] = changingArray[correction - 1]?.replace("T", "");
            }
            if (changingArray[correction - 1].includes("D")) {
              changingArray[correction - 1] = changingArray[correction - 1]?.replace("D", "");
            } else {
              changingArray[correction - 1] = "D" + changingArray[correction - 1];
            }
          }

          setCurrentInput([...changingArray]);
        }

        break;
      case "TRIPLE":
        if (multiplier !== 3) {
          setMultiplier(3);
        } else {
          setMultiplier(1);
        }
        if (correction && settings?.correction) {
          const changingArray = [...currentInput];
          if (changingArray[correction - 1] !== "BULL" && changingArray[correction - 1] !== "BULL'S EYE") {
            if (changingArray[correction - 1].includes("D")) {
              changingArray[correction - 1] = changingArray[correction - 1]?.replace("D", "");
            }
            if (changingArray[correction - 1].includes("T")) {
              changingArray[correction - 1] = changingArray[correction - 1]?.replace("T", "");
            } else {
              changingArray[correction - 1] = "T" + changingArray[correction - 1];
            }
          }

          setCurrentInput([...changingArray]);
        }
        break;
      case "DANEBEN":
        if (correction) {
          const changingArray = [...currentInput];
          changingArray[correction - 1] = "-";
          setCurrentInput([...changingArray]);
        } else {
          const previousScore = gameData[currentPlayer - 1].score - count;
          const currentScore = gameData[currentPlayer - 1].score - count - multiplier * buttonInputKey;
          currentInput.length < 3 &&
            (previousScore <= 1 ? currentScore >= 1 : true) &&
            setCurrentInput([...currentInput, "-"]);
        }
        setCorrection(null);

        setMultiplier(1);
        break;
      case "BESTÄTIGEN":
        setCorrection(null);
        let statcs = [...statistics];
        if (count >= 60 && count < 80) {
          statcs[currentPlayer - 1].over60++;
        }
        if (count >= 80 && count < 100) {
          statcs[currentPlayer - 1].over80++;
        }
        if (count >= 100 && count < 120) {
          statcs[currentPlayer - 1].over100++;
        }
        if (count >= 120 && count < 140) {
          statcs[currentPlayer - 1].over120++;
        }
        if (count >= 140 && count < 180) {
          statcs[currentPlayer - 1].over140++;
        }
        if (count === 180) {
          statcs[currentPlayer - 1].over180++;
        }
        setStatistics(statcs);

        function round(number, decimal = 0) {
          const round = Math.round(number * Math.pow(10, decimal)) / Math.pow(10, decimal);
          return round;
        }
        function random(from, to, decimal = 0) {
          return round(Math.random() * (to - from) + from, decimal);
        }

        const selectNextPlayer = (currentWinner, secondWinner, currentPlayer, allPlayers) => {
          const nextPlayer = currentPlayer < allPlayers.length ? currentPlayer + 1 : 1;
          if (nextPlayer === currentWinner || nextPlayer === secondWinner) {
            selectNextPlayer(currentWinner, secondWinner, nextPlayer, allPlayers);
          } else {
            setCurrentPlayer(nextPlayer);
          }
        };

        const finish = (gameDat) => {
          // IF SCORE LEADS TO 0
          if (gameData[currentPlayer - 1].score - count === 0) {
            const lastInput = currentInput.length > 0 ? currentInput.length - 1 : 0;
            if (
              currentInput?.[lastInput]?.includes("D") ||
              currentInput?.[lastInput]?.includes("BULL'S EYE") ||
              (currentInput.length >= 2 &&
                currentInput?.[lastInput] === "-" &&
                currentInput?.[lastInput - 1]?.includes("D")) ||
              (currentInput.length === 3 &&
                currentInput?.[lastInput] === "-" &&
                currentInput?.[lastInput - 1] === "-" &&
                currentInput?.[lastInput - 2]?.includes("D"))
            ) {
              if (
                allPlayers.length < 3 ||
                (allPlayers.length > 2 && allPlayers.length < 4 && (currentWinner || !settings?.playOn)) ||
                (allPlayers.length > 3 && (secondWinner || !settings?.playOn))
              ) {
                // PLAYER HAS FINISHED LEG - CHANGE CURRENT SET AND LEG
                const sets = gameSettings.setslegs.sets;
                const legs = gameSettings.setslegs.legs;

                if (currentGame.currentLeg >= legs && gameSettings.spielmodus === 2) {
                  let stand = [...spielstand];
                  let legsstand = [...spielstandLegs];
                  legsstand[(currentWinner ?? currentPlayer) - 1] = legsstand[(currentWinner ?? currentPlayer) - 1] + 1;
                  setSpielstandLegs([...legsstand]);
                  const highest = Math.max(...legsstand);
                  legsstand.forEach(function (element, i) {
                    if (element === highest) {
                      stand[i] = stand[i] + 1;
                    }
                  });
                  setSpielstand([...stand]);
                  let standAm = [];
                  allPlayers.forEach((player) => {
                    standAm.push(0);
                  });
                  setSpielstandLegs(standAm);

                  // SET WHO STARTS NEXT
                  if (gameSettings.order === "random") {
                    const randomPlayer = random(1, allPlayers.length);
                    setCurrentPlayer(randomPlayer);
                  } else {
                    setCurrentPlayer((currentGame.currentSet % allPlayers.length) + 1);
                  }
                  if (currentGame.currentSet >= sets) {
                    setGesamtdauer(dauer);
                    online ? history.push("/onlinewinner") : history.push("/winner");
                  } else {
                    setCurrentGame({
                      currentSet: currentGame.currentSet + 1,
                      currentLeg: 1,
                    });
                  }
                  let stats = [...statistics];
                  stats[(currentWinner ?? currentPlayer) - 1].wonLegs++;
                  stats[(currentWinner ?? currentPlayer) - 1].wonSets++;
                  stats.forEach((player, i) => {
                    if (i !== (currentWinner ?? currentPlayer) - 1) {
                      stats[i].lostLegs++;
                      stats[i].lostSets++;
                    }
                  });
                  setStatistics(stats);
                } else {
                  if (gameSettings.spielmodus === 1) {
                    let stand = [...spielstand];
                    stand[(currentWinner ?? currentPlayer) - 1] = stand[(currentWinner ?? currentPlayer) - 1] + 1;
                    setSpielstand([...stand]);
                  } else {
                    let stand = [...spielstandLegs];
                    stand[(currentWinner ?? currentPlayer) - 1] = stand[(currentWinner ?? currentPlayer) - 1] + 1;
                    setSpielstandLegs([...stand]);
                  }

                  // SET WHO STARTS NEXT
                  if (gameSettings.order === "random") {
                    const randomPlayer = random(1, allPlayers.length);
                    setCurrentPlayer(randomPlayer);
                  } else {
                    setCurrentPlayer(((currentGame.currentLeg + currentGame.currentSet - 1) % allPlayers.length) + 1);
                  }

                  if (gameSettings.spielmodus === 1) {
                    // WENN SPIELMODUS ENDLOS IST
                    let allPlayersCopy = [...allPlayers];
                    allPlayersCopy.forEach((player, i) => {
                      allPlayersCopy[i]?.game[0]?.push([]);
                    });
                    setAllPlayers([...allPlayersCopy]);
                    setCurrentGame({
                      currentSet: currentGame.currentSet,
                      currentLeg: currentGame.currentLeg + 1,
                    });
                  } else {
                    setCurrentGame({
                      currentSet: currentGame.currentSet,
                      currentLeg: currentGame.currentLeg + 1,
                    });
                  }
                  let stats = [...statistics];
                  stats[(currentWinner ?? currentPlayer) - 1].wonLegs++;
                  stats.forEach((player, i) => {
                    if (i !== (currentWinner ?? currentPlayer) - 1) {
                      stats[i].lostLegs++;
                    }
                  });
                  setStatistics(stats);
                }
                // RESET THROW COUNT
                gameDat.forEach((player, index) => {
                  gameDat[index].amount = 0;
                });
                setGameData([...gameDat]);
                setCurrentWinner(null);
                setSecondWinner(null);
              } else {
                if (currentWinner) {
                  allPlayers.length > 3 && setSecondWinner(settings?.playOn ? currentPlayer : null);
                } else {
                  allPlayers.length > 2 && setCurrentWinner(settings?.playOn ? currentPlayer : null);
                }
                selectNextPlayer(currentWinner, secondWinner, currentPlayer, allPlayers);
              }
            } else {
              // WENN ZWAR NULL ABER NICHT MIT DOPPEL AUS
              selectNextPlayer(currentWinner, secondWinner, currentPlayer, allPlayers);
            }
          } else {
            selectNextPlayer(currentWinner, secondWinner, currentPlayer, allPlayers);
          }
        };

        // WHEN 3 VALUES ARE ENTERED
        if (currentInput.length === 3) {
          // GET CURRENT PLAYER OF THE INPUT AND ADD SCORE TO THE GAME ARRAY
          let playerArray = [...allPlayers];
          playerArray[currentPlayer - 1].game[currentGame.currentSet - 1][currentGame.currentLeg - 1].push(
            currentInput
          );
          setAllPlayers(playerArray);

          // ADD 3 TO THE THROWN AMOUNT
          let gameDat = [...gameData];
          gameDat[currentPlayer - 1].amount = gameDat[currentPlayer - 1].amount + 3;
          setGameData([...gameDat]);
          finish(gameDat);
          setCurrentInput([]);
        } else if (gameData[currentPlayer - 1].score - count <= 1) {
          let playerArray = [...allPlayers];
          playerArray[currentPlayer - 1].game[currentGame.currentSet - 1][currentGame.currentLeg - 1].push(
            currentInput
          );
          setAllPlayers(playerArray);

          // ADD THROW AMOUNT
          let gameDat = [...gameData];
          gameDat[currentPlayer - 1].amount = gameDat[currentPlayer - 1].amount + currentInput.length;
          setGameData([...gameDat]);
          finish(gameDat);
          setCurrentInput([]);
        }
        let stats = [...statistics];
        stats[currentPlayer - 1].throws += currentInput.length;
        setStatistics(stats);
        setMultiplier(1);
        break;
      default:
        let multi;
        let directInputMulti = buttonInputKey?.includes("D") ? 2 : buttonInputKey?.includes("T") ? 3 : 1;
        if (multiplier === 2) {
          multi = "D";
        } else if (multiplier === 3) {
          multi = "T";
        } else {
          multi = "";
        }
        if (correction) {
          const changingArray = [...currentInput];
          changingArray[correction - 1] = multi + buttonInputKey;
          setCurrentInput([...changingArray]);
        } else {
          const previousScore = gameData[currentPlayer - 1].score - count;
          const currentScore =
            gameData[currentPlayer - 1].score -
            count -
            (directInputMulti > 1 ? directInputMulti : multiplier) *
              parseInt(buttonInputKey?.replace("D", "").replace("T", ""));
          currentInput.length < 3 &&
            (previousScore <= 1 ? currentScore >= 1 : true) &&
            setCurrentInput([...currentInput, multi + buttonInputKey]);
        }
        setCorrection(null);
        setMultiplier(1);
        break;
    }
  };

  const [gameData, setGameData] = useState([]);

  useEffect(() => {
    // IF PLAYER STATE CHANGES
    let gameDat = [...gameData];
    gameDat.forEach((player, index) => {
      const thrown = allPlayers[index].game[currentGame.currentSet - 1][currentGame.currentLeg - 1];

      let throwAmount = 0;
      let lasttype = 1;
      thrown?.forEach((threescore) => {
        let three = 0;
        threescore.forEach((onescore) => {
          let countedscore;
          if (onescore.includes("D")) {
            countedscore = 2 * parseInt(onescore.replace("D", ""));
            lasttype = 2;
          } else if (onescore.includes("T")) {
            countedscore = 3 * parseInt(onescore.replace("T", ""));
            lasttype = 3;
          } else if (onescore === "BULL") {
            countedscore = 25;
            lasttype = 1;
          } else if (onescore === "BULL'S EYE") {
            lasttype = 2;
            countedscore = 50;
          } else if (onescore === "-") {
            lasttype = 0;
            countedscore = 0;
          } else {
            lasttype = 1;
            countedscore = parseInt(onescore);
          }
          three = three + countedscore;
        });

        if (gameSettings.startzahl - throwAmount - three === 0 && lasttype !== 2) {
          // GENAU AUF NULL ABER SINGLE OUT
        } else if (gameSettings.startzahl - throwAmount - three === 1) {
          // AUF 1
        } else if (gameSettings.startzahl - throwAmount - three < 0) {
          // UEBERWERFEN
        } else {
          throwAmount = throwAmount + three;
        }
      });

      if (gameSettings.startzahl - throwAmount < 0) {
        gameDat[index].score = gameData[index].score;
      } else if (gameSettings.startzahl - throwAmount === 0 && lasttype !== 2) {
        // WENN MAN GENAU AUF NULL KOMMT ABER NED RICHTIG
        gameDat[index].score = gameData[index].score;
      } else {
        gameDat[index].score = gameSettings.startzahl - throwAmount;
      }
    });

    setGameData([...gameDat]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPlayers]);

  useEffect(() => {
    setCurrentPlayer(1);
    setCurrentGame({
      currentSet: 1,
      currentLeg: 1,
    });

    let conversion = [...allPlayers];
    conversion.forEach((conv, index) => {
      conversion[index] = {
        ...conversion[index],
        score: gameSettings.startzahl,
        amount: 0,
      };
    });
    setGameData([...conversion]);

    // SetUp Player Arrays
    let tempPlayerArray = [...allPlayers];
    tempPlayerArray.forEach((player) => {
      for (let i = 0; i < gameSettings.setslegs.sets; i++) {
        player.game.push([]);
      }
      player.game.forEach((set) => {
        for (let i = 0; i < gameSettings.setslegs.legs; i++) {
          set.push([]);
        }
      });
    });
    setAllPlayers(tempPlayerArray);

    if (online) {
      socket?.emit("join-room", userId.toUpperCase(), (msg) => {
        // console.log(msg);
      });

      socket.on("receive-data", (data) => {
        if (data.event === "joined" && data.sender === "listener" && data.room === userId.toUpperCase()) {
          setSocketTrigger(!socketTrigger);
        }
        if (data.event === "joined" && data.sender === "listener" && data.room === userId.toUpperCase()) {
          socket.emit("send-data", userId.toUpperCase(), {
            event: "status",
            sender: "game",
            room: userId.toUpperCase(),
          });
        }
        if (data.event === "input" && data.sender === "input") {
          const inputString = `${data.payload}`;
          setSocketInput((prev) => ({
            value: inputString,
            trigger: !prev?.trigger,
          }));
        }
      });
    }

    return function cleanup() {
      socket?.off("receive-data");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (online && socketInput?.value) {
      if (socketInput?.value === "DANEBEN") {
        keyboardHandler({
          target: {
            innerText: "DANEBEN",
          },
        });
      } else {
        keyboardHandler({
          target: {
            innerText: socketInput?.value,
          },
        });
      }
    }
  }, [socketInput]);

  useEffect(() => {
    if (online) {
      socket.emit("send-data", userId.toUpperCase(), {
        event: "update",
        sender: "game",
        room: userId.toUpperCase(),
        payload: {
          gameData: gameData,
          currentInput: currentInput,
          currentPlayer: currentPlayer,
          spielstand: spielstand,
          gameSettings: {
            spielmodus: gameSettings.spielmodus,
            startzahl: gameSettings.startzahl,
          },
          currentGame: {
            currentSet: currentGame.currentSet,
            currentLeg: currentGame.currentLeg,
          },
          currentWinner,
          secondWinner,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameData, currentInput, socketTrigger]);

  return (
    <Card
      style={{
        background: !settings.darkmode ? "var(--main-dark-color)" : "var(--main-light-color)",
      }}
    >
      <header>
        <h2 style={{ color: !settings.darkmode ? "#fff" : "#000" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="50.846" height="50.846" viewBox="0 0 50.846 50.846">
            <path
              id="Vereinigungsmenge_50"
              data-name="Vereinigungsmenge 50"
              d="M2366-4124.886a25.218,25.218,0,0,1,2.483-10.66l4.571,4.568a19.248,19.248,0,0,0-1,6.092,19.387,19.387,0,0,0,19.364,19.366,19.389,19.389,0,0,0,19.366-19.366,19.387,19.387,0,0,0-19.366-19.364,19.233,19.233,0,0,0-6.09,1l-4.57-4.569a25.183,25.183,0,0,1,10.66-2.484,25.521,25.521,0,0,1,25.423,25.422,25.522,25.522,0,0,1-25.423,25.423A25.521,25.521,0,0,1,2366-4124.886Zm12.016,0a13.244,13.244,0,0,1,.824-4.469h1.795l3.444,3.443a7.223,7.223,0,0,0-.1,1.025,7.457,7.457,0,0,0,7.448,7.448,7.458,7.458,0,0,0,7.449-7.448,7.458,7.458,0,0,0-7.449-7.448,7.252,7.252,0,0,0-1.025.1l-3.444-3.444v-1.795a13.267,13.267,0,0,1,4.469-.823,13.42,13.42,0,0,1,13.406,13.406,13.421,13.421,0,0,1-13.406,13.406A13.422,13.422,0,0,1,2378.017-4124.886Zm12.382,1.073c-.008-.009-.02-.01-.029-.02l-8.5-8.5h-5.341a1.492,1.492,0,0,1-1.054-.437l-9.036-9.037a1.49,1.49,0,0,1-.324-1.624,1.493,1.493,0,0,1,1.377-.919h4.567v-4.469a1.489,1.489,0,0,1,.921-1.376,1.489,1.489,0,0,1,1.622.323l8.938,9.037a1.489,1.489,0,0,1,.437,1.053v5.341l8.5,8.5c.008.008.01.02.019.028a1.469,1.469,0,0,1-.048,2.1,1.477,1.477,0,0,1-1.024.417A1.481,1.481,0,0,1,2390.4-4123.813Z"
              transform="translate(-2366 4150.309)"
              fill={!settings.darkmode ? "#ffffff" : "#000000"}
            />
          </svg>
          Spiel
        </h2>
        <div className="infos-top">
          <button
            onClick={() => {
              if (online) {
                socket?.volatile.emit("send-data", userId.toUpperCase(), {
                  event: "stop",
                  sender: "game",
                  room: userId.toUpperCase(),
                });
              }
              setGesamtdauer(dauer);
              online ? history.push("/onlinewinner") : history.push("/winner");
            }}
            className="exit blue-btn"
          >
            BEENDEN
          </button>
          <InputOutput
            title={`Spielstand`}
            align={"center"}
            width={"200%"}
            placeholder={"Spielstand"}
            state={spielstand.join(":")}
            setState={setSpielstand}
            key={"1"}
            darkmode={!settings.darkmode}
            darker
          />
          {gameSettings?.spielmodus === 2 && (
            <InputOutput
              title={`Set`}
              align={"center"}
              width={"100%"}
              placeholder={"Spielstand"}
              state={currentGame.currentSet}
              setState={setCurrentGame}
              key={"2"}
              darkmode={!settings.darkmode}
              darker
            />
          )}
          <InputOutput
            title={gameSettings?.spielmodus === 2 ? `Leg` : "Spiel"}
            align={"center"}
            width={"100%"}
            placeholder={"Spielstand"}
            state={currentGame.currentLeg}
            setState={setCurrentGame}
            key={"3"}
            darkmode={!settings.darkmode}
            darker
          />
          <InputOutput
            title={`Dauer`}
            align={"center"}
            width={"180%"}
            placeholder={"Spielstand"}
            state={dauer}
            setState={setDauer}
            key={"4"}
            darkmode={!settings.darkmode}
            darker
          />
        </div>
      </header>

      <section className="viewport-display">
        {gameData &&
          gameSettings &&
          currentGame &&
          gameData &&
          allPlayers &&
          gameData.map((player, index) => {
            return (
              <Counter
                darkmode={!settings.darkmode}
                player={player}
                gameSettings={gameSettings}
                currentGame={currentGame}
                gameData={player}
                currentInput={currentInput}
                currentPlayer={currentPlayer}
                playerNr={index + 1}
                allPlayers={allPlayers}
                statistics={statistics}
                setStatistics={setStatistics}
                currentWinner={currentWinner}
                secondWinner={secondWinner}
                key={index + 4}
              />
            );
          })}
      </section>
      {online && userId && (
        <div
          style={{
            color: !settings.darkmode ? "white" : "black",
            opacity: viewkey ? 0.6 : 0.1,
            fontSize: viewkey ? "1.1rem" : "0.75rem",
            marginBottom: viewkey ? "0.6rem" : "0.05rem",
          }}
          className="key"
          onClick={() => {
            setViewkey(!viewkey);
            // copy to clipboard
            navigator.clipboard.writeText(userId.toUpperCase());
          }}
        >
          ID: {userId.toUpperCase()}
        </div>
      )}
      <section className="keyboard">
        <div className={!settings.darkmode ? "keyboard-input darkinput" : "keyboard-input"}>
          {currentInput &&
            currentInput.map((input, index) => {
              return (
                <span
                  className={index === correction - 1 ? "active-correct" : undefined}
                  index={index}
                  onClick={correctHandler}
                  key={index + 8}
                >
                  {input}
                </span>
              );
            })}
          {currentInput.length === 1 && <span></span>}
          {currentInput.length === 1 && <span></span>}
          {currentInput.length === 2 && <span></span>}
        </div>
        <div className={settings?.alignment ? "standard" : "standard upside-down"}>
          {inputs.map((input, index) => {
            return (
              <button
                className={
                  !settings.darkmode
                    ? input === "BESTÄTIGEN"
                      ? "blue-btn"
                      : input === "DOUBLE"
                      ? multiplier === 2
                        ? "dark-num-btn active"
                        : "dark-num-btn inactive"
                      : input === "TRIPLE"
                      ? multiplier === 3
                        ? "dark-num-btn active"
                        : "dark-num-btn inactive"
                      : "dark-num-btn"
                    : input === "BESTÄTIGEN"
                    ? "blue-btn"
                    : input === "DOUBLE"
                    ? multiplier === 2
                      ? "light-num-btn active"
                      : "light-num-btn inactive"
                    : input === "TRIPLE"
                    ? multiplier === 3
                      ? "light-num-btn active"
                      : "light-num-btn inactive"
                    : "light-num-btn"
                }
                key={index + 12}
                onClick={keyboardHandler}
              >
                {input}
              </button>
            );
          })}
        </div>
      </section>
    </Card>
  );
};

const Card = styled.div`
  animation: pageSlideInTransition 0.5s forwards;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  min-width: 20rem;
  min-height: 46rem;
  @media (max-width: 20rem) {
    overflow: scroll;
  }
  @media (max-width: 50rem) {
    overflow: scroll;
  }
  @media (min-width: 45rem) {
    min-height: 35rem;
  }

  display: flex;
  flex-direction: column;
  .key {
    text-align: center;
    height: 0.8rem;
    max-height: 0.8rem;
    margin-top: -0.9rem;
    transition: all 0.5s ease;
  }
  .viewport-display {
    width: 100%;
    height: calc(30% - 3rem);
    min-height: 17rem;
    @media (min-width: 45rem) {
      height: calc(60% - 3rem);
    }
    @media (max-width: 45rem) {
      min-height: 12rem;
    }

    @media (max-width: 35rem) {
      min-height: 10.3rem;
    }
    @media (max-width: 30rem) {
      min-height: 9rem;
    }
    @media (max-width: 406px) {
      overflow-x: auto;
    }

    display: flex;
    align-items: stretch;
    padding: 0.5rem 0.9rem;
  }

  .keyboard {
    height: 50%;
    padding: 0.5rem;
    @media (max-width: 45rem) {
      height: 70%;
      max-height: 70%;
    }
    .darkinput {
      background: #3d3d3d !important;
      color: white;
    }
    .keyboard-input {
      background: #d3d3d3;
      height: 3rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-weight: 600;
      font-size: 1.3rem;
      border-radius: 0.5rem;
      margin-bottom: 0.2rem;
      width: calc(100% - 0.4rem);
      margin-left: 0.2rem;
      @media (max-width: 40rem) {
        height: 2.5rem;
        font-size: 1.2rem;
      }
      .active-correct {
        color: #1afa8a;
      }
      span {
        cursor: pointer;
        min-width: 2rem;
        text-align: center;
        border-radius: 1rem;
      }
    }
    .upside-down button {
      &:nth-child(-n + 4) {
        order: 1;
      }
    }
    .standard {
      height: calc(100% - 3rem);
      max-height: calc(100% - 3rem);
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: stretch;
      .dark-num-btn {
        background: #252525;
        color: white;
        &:active {
          background: #2c2c2c;
        }
      }
      .light-num-btn {
        background: #e9e9e9;
        color: black;
        &:active {
          background: #d8d8d8;
        }
      }
      .active {
        background: #10b965;
        color: #02361c;
        &:active {
          background: #10b965;
        }
      }
      .inactive {
        &:active {
          background: #10b965;
          color: #02361c;
        }
      }
      button {
        margin: 0.2rem;
        border: none;
        flex: 1 0 13%;
        &:nth-child(-n + 4) {
          flex: 1 0 23%;
        }
        @media (max-width: 720px) {
          flex: 1 0 25%;
          &:nth-child(-n + 2) {
            flex: 1 0 40%;
          }
          &:nth-child(3) {
            flex: 1 0 40%;
            height: 3rem;
            order: 50;
          }
          &:nth-child(4) {
            flex: 1 0 40%;
            height: 3rem;
            order: 51;
          }
        }
      }
    }
  }

  header {
    width: 100%;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem 0 0.5rem;
    @media (max-width: 40rem) {
      height: 4rem;
      padding: 0.5rem 0.5rem 0 0.4rem;
    }
    .exit {
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 7rem;
      margin-top: 0.47rem;
    }
    h2 {
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin-right: 2rem;
      margin-top: -0.8rem;
      @media (max-width: 40rem) {
        display: none;
        font-size: 1.2rem;
      }
      svg {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
      }
    }
    div {
      width: 100%;
      max-width: 50rem;
      display: flex;
      input {
        width: calc(100% - 1rem) !important;
        margin: 0 0.5rem !important;
      }
    }
  }
`;

export default Game;
