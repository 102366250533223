import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { CheckSelector, Selector } from "../components/GuiElements";
import { useHistory } from "react-router-dom";

const NewGame = ({
  setCurrentPlayer,
  currentPlayer,
  setCurrentGame,
  currentGame,
  setGameSettings,
  gameSettings,
  setSettings,
  settings,
  allPlayers,
  setAllPlayers,
}) => {
  const history = useHistory();
  const [check, setCheck] = useState(true);
  const [inverseCheck, setInverseCheck] = useState(false);

  const [playerAmount, setPlayerAmount] = useState(2);
  const [playerOptions] = useState([1, 2, 3, 4]);

  const [modeAmount, setModeAmount] = useState(501);
  const [modeOptions] = useState([301, 401, 501, 601, 701, 801, 901]);

  const [legsAmount, setLegsAmount] = useState(1);
  const [legsOptions, setLegsOptions] = useState([]);

  const [setsAmount, setSetsAmount] = useState(1);
  const [setsOptions, setSetsOptions] = useState([]);

  useEffect(() => {
    setLegsOptions(Array.from({ length: 50 }, (_, i) => i + 1));
    setSetsOptions(Array.from({ length: 50 }, (_, i) => i + 1));
    setLegsAmount(1);
    setSetsAmount(1);
  }, [playerAmount]);

  useEffect(() => {
    setGameSettings({
      spieleranzahl: playerAmount,
      startzahl: modeAmount,
      spielmodus: check ? 1 : 2, // 1 = endlos, 2 = sets und legs
      setslegs: {
        // Wenn Spielmodus 2 ist
        sets: setsAmount,
        legs: legsAmount,
      },
      order: "following", // following oder random
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerAmount, modeAmount, check, setsAmount, legsAmount]);

  return (
    <Card
      style={{
        minHeight: inverseCheck ? "35rem" : "24rem",
        background: !settings.darkmode
          ? "var(--main-dark-color)"
          : "var(--main-light-color)",
      }}
    >
      <span
        style={{
          color: !settings.darkmode
            ? "var(--main-light-color)"
            : "var(--main-dark-color)",
        }}
        onClick={() => {
          history.push("/");
        }}
        className="back"
      >
        {"<"}&nbsp;&nbsp;&nbsp;
      </span>
      <h1
        className="newgame-title"
        style={{ color: !settings.darkmode ? "#ffffff" : "#000000" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50.846"
          height="50.846"
          viewBox="0 0 50.846 50.846"
        >
          <path
            id="Vereinigungsmenge_50"
            data-name="Vereinigungsmenge 50"
            d="M2366-4124.886a25.218,25.218,0,0,1,2.483-10.66l4.571,4.568a19.248,19.248,0,0,0-1,6.092,19.387,19.387,0,0,0,19.364,19.366,19.389,19.389,0,0,0,19.366-19.366,19.387,19.387,0,0,0-19.366-19.364,19.233,19.233,0,0,0-6.09,1l-4.57-4.569a25.183,25.183,0,0,1,10.66-2.484,25.521,25.521,0,0,1,25.423,25.422,25.522,25.522,0,0,1-25.423,25.423A25.521,25.521,0,0,1,2366-4124.886Zm12.016,0a13.244,13.244,0,0,1,.824-4.469h1.795l3.444,3.443a7.223,7.223,0,0,0-.1,1.025,7.457,7.457,0,0,0,7.448,7.448,7.458,7.458,0,0,0,7.449-7.448,7.458,7.458,0,0,0-7.449-7.448,7.252,7.252,0,0,0-1.025.1l-3.444-3.444v-1.795a13.267,13.267,0,0,1,4.469-.823,13.42,13.42,0,0,1,13.406,13.406,13.421,13.421,0,0,1-13.406,13.406A13.422,13.422,0,0,1,2378.017-4124.886Zm12.382,1.073c-.008-.009-.02-.01-.029-.02l-8.5-8.5h-5.341a1.492,1.492,0,0,1-1.054-.437l-9.036-9.037a1.49,1.49,0,0,1-.324-1.624,1.493,1.493,0,0,1,1.377-.919h4.567v-4.469a1.489,1.489,0,0,1,.921-1.376,1.489,1.489,0,0,1,1.622.323l8.938,9.037a1.489,1.489,0,0,1,.437,1.053v5.341l8.5,8.5c.008.008.01.02.019.028a1.469,1.469,0,0,1-.048,2.1,1.477,1.477,0,0,1-1.024.417A1.481,1.481,0,0,1,2390.4-4123.813Z"
            transform="translate(-2366 4150.309)"
            fill={!settings.darkmode ? "#ffffff" : "#000000"}
          />
        </svg>
        Neues Spiel
      </h1>
      <div className="selectors">
        <Selector
          input
          align={"center"}
          width={"100%"}
          after={" Spieler"}
          options={playerOptions}
          state={playerAmount}
          setState={setPlayerAmount}
          darkmode={!settings.darkmode}
        />
        <Selector
          input
          align={"center"}
          width={"100%"}
          after={""}
          options={modeOptions}
          state={modeAmount}
          setState={setModeAmount}
          darkmode={!settings.darkmode}
        />
      </div>
      <div className="playmode">
        <CheckSelector
          label={"Endlos"}
          setState={setCheck}
          state={check}
          setInfluencer={setInverseCheck}
          marginR={"1rem"}
          darkmode={!settings.darkmode}
        />
        <CheckSelector
          label={"Sets und Legs"}
          setState={setInverseCheck}
          state={inverseCheck}
          setInfluencer={setCheck}
          darkmode={!settings.darkmode}
        />
      </div>

      <div
        className="selectors"
        style={{
          height: inverseCheck ? "9rem" : "0rem",
          opacity: inverseCheck ? 1 : 0,
        }}
      >
        <Selector
          input
          align={"center"}
          width={"100%"}
          after={setsAmount === 1 ? " Set" : " Sets"}
          options={setsOptions}
          state={setsAmount}
          setState={setSetsAmount}
          darkmode={!settings.darkmode}
        />
        <Selector
          input
          align={"center"}
          width={"100%"}
          after={legsAmount === 1 ? " Leg" : " Legs"}
          options={legsOptions}
          state={legsAmount}
          setState={setLegsAmount}
          darkmode={!settings.darkmode}
        />
      </div>

      <button
        onClick={() => {
          history.push("/playerselection");
        }}
        className="blue-btn newgame-forwards"
      >
        Weiter
      </button>
    </Card>
  );
};

const Card = styled.div`
  width: 100%;
  height: 100vh;
  animation: pageSlideInTransition 0.2s forwards;
  min-width: 13rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .back {
    position: absolute;
    top: 0.5rem;
    left: 2rem;
    font-size: 1.5rem;
    font-weight: 700;
    cursor: pointer;
  }
  .newgame-title {
    font-size: 1.5rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    svg {
      width: 2rem;
      height: 2rem;
      margin-right: 0.5rem;
    }
  }
  .selectors {
    width: 22rem;
    margin-bottom: 0.5rem;
    overflow: hidden;
    transition: height 0.2s ease, opacity 0.1s ease;
  }
  .playmode {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    width: calc(100% - 4rem);
    span {
      white-space: nowrap;
    }
  }
  .newgame-forwards {
    width: 22rem;
    margin: 0.5rem;
  }

  @media (max-width: 22rem) {
    @media (max-width: 17rem) {
      .newgame-title {
        flex-direction: column;
      }
      .playmode {
        flex-direction: column;
      }
    }

    .selectors,
    .newgame-forwards,
    .playmode {
      width: calc(100% - 1rem);
    }
  }
`;

export default NewGame;
