import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { InputOutput } from "../components/GuiElements";
import { useHistory } from "react-router-dom";
import Counter from "../components/Counter";

const Game = ({ settings, socket, roomId }) => {
  const [gameData, setGameData] = useState(null);
  const [currentInput, setCurrentInput] = useState(null);
  const [spielstand, setSpielstand] = useState(null);
  const [gesamtspielstand, setGesamtSpielstand] = useState(null);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [currentGame, setCurrentGame] = useState(null);
  const [gameSettings, setGameSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [winning, setWinning] = useState(false);
  const [gesamtdauer, setGesamtdauer] = useState(null);
  const [currentWinner, setCurrentWinner] = useState(null);
  const [secondWinner, setSecondWinner] = useState(null);
  const history = useHistory();

  useEffect(() => {
    socket?.volatile.emit("join-room", roomId.toUpperCase(), (msg) => {
      socket.emit("send-data", roomId, {
        event: "joined",
        sender: "listener",
        room: roomId,
      });
    });
    socket.on("receive-data", (data) => {
      if (
        data.event === "update" &&
        data.sender === "game" &&
        data.room === roomId
      ) {
        let payload = data?.payload;
        setGameData(payload?.gameData);
        setCurrentInput(payload?.currentInput);
        setCurrentPlayer(payload?.currentPlayer);
        setCurrentGame(payload?.currentGame);
        setSpielstand(payload?.spielstand);
        setGameSettings(payload?.gameSettings);
        setCurrentWinner(payload?.currentWinner);
        setSecondWinner(payload?.secondWinner);
        setIsLoading(false);
        setWinning(false);
      }
      if (
        data.event === "status" &&
        data.sender === "game" &&
        data.room === roomId
      ) {
        setIsLoading(true);
      }
      if (
        data.event === "stop" &&
        data.sender === "game" &&
        data.room === roomId
      ) {
        setIsLoading(false);
        setGameData(null);
        setCurrentInput(null);
        setCurrentPlayer(null);
        setCurrentGame(null);
        setSpielstand(null);
        setGameSettings(null);
        setIsLoading(false);
        setWinning(false);
      }

      if (
        data.event === "winner" &&
        data.sender === "game" &&
        data.room === roomId
      ) {
        setWinning(data.payload.winning);
        setGesamtSpielstand(data.payload.spielstand);
        setGesamtdauer(data.payload.gesamtdauer);
        setIsLoading(false);
      }
    });

    return function cleanup() {
      socket?.off("receive-data");
    };
  }, [roomId, socket]);

  return (
    <Card
      style={{
        background: !settings.darkmode
          ? "var(--main-dark-color)"
          : "var(--main-light-color)",
        minHeight: "25rem",
      }}
    >
      <header style={{ height: "4rem" }}>
        <h2
          onClick={() => {
            socket?.emit("leave-room", roomId.toUpperCase(), (msg) => {
              // console.log(msg);
            });
            history.push("/");
          }}
          style={{
            color: !settings.darkmode ? "#fff" : "#000",
          }}
        >
          <span className="back">{"<"}&nbsp;&nbsp;&nbsp;</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill={!settings.darkmode ? "#fff" : "#000"}
              d="M6.043 19.496l-1.482 1.505c-2.791-2.201-4.561-5.413-4.561-9.001s1.77-6.8 4.561-9l1.482 1.504c-2.326 1.835-3.804 4.512-3.804 7.496s1.478 5.661 3.804 7.496zm.675-7.496c0-1.791.887-3.397 2.282-4.498l-1.481-1.502c-1.86 1.467-3.04 3.608-3.04 6s1.18 4.533 3.04 6l1.481-1.502c-1.396-1.101-2.282-2.707-2.282-4.498zm15.043 0c0-2.984-1.478-5.661-3.804-7.496l1.482-1.504c2.791 2.2 4.561 5.412 4.561 9s-1.77 6.8-4.561 9.001l-1.482-1.505c2.326-1.835 3.804-4.512 3.804-7.496zm-6.761 4.498l1.481 1.502c1.86-1.467 3.04-3.608 3.04-6s-1.18-4.533-3.04-6l-1.481 1.502c1.396 1.101 2.282 2.707 2.282 4.498s-.886 3.397-2.282 4.498zm-3-7.498c-1.656 0-3 1.343-3 3s1.344 3 3 3 3-1.343 3-3-1.344-3-3-3z"
            />
          </svg>
          <span> Live</span>
        </h2>
        <div className="infos-top">
          {spielstand && (
            <InputOutput
              title={`Spielstand`}
              align={"center"}
              width={"200%"}
              placeholder={"Spielstand"}
              state={spielstand.join(":")}
              setState={setSpielstand}
              key={"1"}
              darkmode={!settings.darkmode}
              darker
            />
          )}
          {gameSettings?.spielmodus === 2 && currentGame?.currentSet && (
            <InputOutput
              title={`Set`}
              align={"center"}
              width={"100%"}
              placeholder={"Spielstand"}
              state={currentGame.currentSet}
              setState={setCurrentGame}
              key={"2"}
              darkmode={!settings.darkmode}
              darker
            />
          )}
          {currentGame?.currentLeg && (
            <InputOutput
              title={gameSettings?.spielmodus === 2 ? `Leg` : "Spiel"}
              align={"center"}
              width={"100%"}
              placeholder={"Spielstand"}
              state={currentGame.currentLeg}
              setState={setCurrentGame}
              key={"3"}
              darkmode={!settings.darkmode}
              darker
            />
          )}
        </div>
      </header>
      {isLoading && !winning && (
        <Loader>
          <div
            className="spinner"
            style={{
              backgroundColor: settings.darkmode
                ? "var(--main-dark-color)"
                : "var(--main-light-color)",
            }}
          ></div>
          <p
            style={{
              color: settings.darkmode
                ? "var(--main-dark-color)"
                : "var(--main-light-color)",
            }}
          >
            Warte auf Spiel
          </p>
        </Loader>
      )}
      {(isLoading && !winning) ||
        (!isLoading && !gameData && !gameSettings && !winning && (
          <Loader>
            <div
              className="spinner"
              style={{
                backgroundColor: settings.darkmode
                  ? "var(--main-dark-color)"
                  : "var(--main-light-color)",
              }}
            ></div>
            <p
              style={{
                color: settings.darkmode
                  ? "var(--main-dark-color)"
                  : "var(--main-light-color)",
              }}
            >
              Warte auf Spiel
            </p>
          </Loader>
        ))}
      <section
        className="viewport-display"
        style={{
          minHeight: "16.5rem",
          height: "calc(100vh - 8rem)",
        }}
      >
        {gameData &&
          spielstand &&
          currentPlayer &&
          currentGame &&
          gameSettings &&
          gameData.map((player, index) => {
            return (
              <Counter
                darkmode={!settings.darkmode}
                player={player}
                gameSettings={gameSettings}
                currentGame={currentGame}
                gameData={player}
                currentInput={currentInput}
                currentPlayer={currentPlayer}
                playerNr={index + 1}
                allPlayers={gameData}
                currentWinner={currentWinner}
                secondWinner={secondWinner}
              />
            );
          })}
      </section>
      {currentInput && (
        <section className="keyboard">
          <div
            className={
              !settings.darkmode ? "keyboard-input darkinput" : "keyboard-input"
            }
          >
            {currentInput &&
              currentInput.map((input, index) => {
                return (
                  <span className={index} index={index}>
                    {input}
                  </span>
                );
              })}
            {currentInput?.length === 1 && <span></span>}
            {currentInput?.length === 1 && <span></span>}
            {currentInput?.length === 2 && <span></span>}
          </div>
        </section>
      )}

      {winning && gesamtdauer && gesamtspielstand && (
        <Winner>
          <h1
            className="main-title"
            style={{ color: !settings.darkmode ? "#ffffff" : "#000000" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50.846"
              height="50.846"
              viewBox="0 0 50.846 50.846"
            >
              <path
                id="Vereinigungsmenge_50"
                data-name="Vereinigungsmenge 50"
                d="M2366-4124.886a25.218,25.218,0,0,1,2.483-10.66l4.571,4.568a19.248,19.248,0,0,0-1,6.092,19.387,19.387,0,0,0,19.364,19.366,19.389,19.389,0,0,0,19.366-19.366,19.387,19.387,0,0,0-19.366-19.364,19.233,19.233,0,0,0-6.09,1l-4.57-4.569a25.183,25.183,0,0,1,10.66-2.484,25.521,25.521,0,0,1,25.423,25.422,25.522,25.522,0,0,1-25.423,25.423A25.521,25.521,0,0,1,2366-4124.886Zm12.016,0a13.244,13.244,0,0,1,.824-4.469h1.795l3.444,3.443a7.223,7.223,0,0,0-.1,1.025,7.457,7.457,0,0,0,7.448,7.448,7.458,7.458,0,0,0,7.449-7.448,7.458,7.458,0,0,0-7.449-7.448,7.252,7.252,0,0,0-1.025.1l-3.444-3.444v-1.795a13.267,13.267,0,0,1,4.469-.823,13.42,13.42,0,0,1,13.406,13.406,13.421,13.421,0,0,1-13.406,13.406A13.422,13.422,0,0,1,2378.017-4124.886Zm12.382,1.073c-.008-.009-.02-.01-.029-.02l-8.5-8.5h-5.341a1.492,1.492,0,0,1-1.054-.437l-9.036-9.037a1.49,1.49,0,0,1-.324-1.624,1.493,1.493,0,0,1,1.377-.919h4.567v-4.469a1.489,1.489,0,0,1,.921-1.376,1.489,1.489,0,0,1,1.622.323l8.938,9.037a1.489,1.489,0,0,1,.437,1.053v5.341l8.5,8.5c.008.008.01.02.019.028a1.469,1.469,0,0,1-.048,2.1,1.477,1.477,0,0,1-1.024.417A1.481,1.481,0,0,1,2390.4-4123.813Z"
                transform="translate(-2366 4150.309)"
                fill={!settings.darkmode ? "#ffffff" : "#000000"}
              />
            </svg>
            {winning.length > 2 ? winning.join(", ") : winning.join(" und ")}{" "}
            {winning.length > 1 ? "haben" : "hat"} gewonnen!
          </h1>

          <div className="info">
            <InputOutput
              title={`Dauer`}
              align={"center"}
              width={"100%"}
              placeholder={"Dauer"}
              state={gesamtdauer}
              setState={setGesamtSpielstand}
              key={"1"}
              darkmode={!settings.darkmode}
            />

            <InputOutput
              title={`Spielstand`}
              align={"center"}
              width={"100%"}
              placeholder={"Spielstand"}
              state={gesamtspielstand.join(":")}
              setState={setGesamtSpielstand}
              key={"2"}
              darkmode={!settings.darkmode}
            />
          </div>
        </Winner>
      )}
    </Card>
  );
};

const Card = styled.div`
  animation: pageSlideInTransition 0.5s forwards;
  width: 100%;
  height: 100vh;
  overflow: hidden !important;
  min-width: 20rem;
  display: flex;
  flex-direction: column;
  .viewport-display {
    width: 100%;
    display: flex;
    align-items: stretch;
    padding: 0.9rem;
  }

  .keyboard {
    height: 10vh;
    padding: 0.5rem;
    @media (max-width: 45rem) {
      height: 10vh;
      max-height: 10vh;
    }
    .darkinput {
      background: #3d3d3d !important;
      color: white;
    }
    .keyboard-input {
      background: #d3d3d3;
      height: 3rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-weight: 600;
      font-size: 1.2rem;
      border-radius: 0.5rem;
      margin-bottom: 0.2rem;
      width: calc(100% - 0.4rem);
      margin-left: 0.2rem;
      @media (max-width: 40rem) {
        height: 2.5rem;
      }
      span {
        min-width: 2.5rem;
        text-align: center;
        border-radius: 1rem;
      }
    }
  }

  header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem 0 0.5rem;
    @media (max-width: 40rem) {
      height: 4rem;
      padding: 0.5rem 0.5rem 0 0.4rem;
    }
    @media (max-height: 40rem) {
      height: 5rem;
      padding: 0.5rem 0.5rem 0 0.4rem;
    }
    .exit {
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 7rem;
      margin-top: 0.47rem;
    }
    h2 {
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin-right: 2rem;
      margin-left: 1rem;
      margin-top: -0.8rem;
      cursor: pointer;
      span {
        line-height: 1.4rem;
        margin-top: -0.2rem;
      }
      svg {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 0.8rem;
      }
    }
    div {
      width: 100%;
      max-width: 50rem;
      display: flex;
      input {
        width: calc(100% - 1rem) !important;
        margin: 0 0.5rem !important;
      }
    }
  }
`;

const Winner = styled.div`
  animation: pageSlideInTransition 0.2s forwards;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  pointer-events: none;
  .info {
    width: 22rem;
    max-width: 100%;
    display: flex;
    transform: scale(1.3);
    div {
      &:first-child {
        margin-right: 0.5rem;
      }
    }
  }

  .main-title {
    font-size: 2rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    margin-bottom: 2rem;
    text-align: center;
    svg {
      width: 3rem;
      height: 3rem;
      margin-bottom: 1rem;
    }
  }
  .main-newgame,
  .main-player {
    width: 22rem;
  }
  .main-player {
    margin-bottom: 2rem;
  }
  .stat-dark-btn {
    color: black;
    background: #ffffff;
    border: 2px solid #ffffff;
    &:active {
      background: #eeeeee;
    }
  }
  .stat-light-btn {
    color: white;
    background: black;
    border: 2px solid black;
    overflow: hidden;
    text-overflow: ellipsis;
    &:active {
      background: #131313;
    }
  }
  @media (max-width: 22rem) {
    @media (max-width: 15rem) {
      .main-title {
        flex-direction: column;
      }
    }

    .main-newgame,
    .main-player {
      width: calc(100% - 1rem);
    }
  }
`;

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: absolute;
  font-size: 1.2rem;
  opacity: 0.5;
  pointer-events: none;
  .spinner {
    width: 4rem;
    height: 4rem;
    margin: 1rem;
    opacity: 0.4;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }
`;

export default Game;
