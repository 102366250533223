import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import Checkout from "../util/Checkouts";
import { useWindowSize } from "../util/MediaQuery";

export const CheckoutDisplay = ({ score, darkmode, players }) => {
  const mediaQuery = useWindowSize().width < 720;
  return (
    <>
      {Checkout(score).length !== 0 && (
        <StyledCheckout
          style={{
            width: players <= 2 ? "38vw" : players <= 3 ? "24vw" : "18vw",
            fontSize: players <= 2 ? (mediaQuery ? "0.65rem" : "0.8rem") : mediaQuery ? "1.8vw" : "0.8rem",
            background: darkmode ? "#363636" : "#d6d6d6",
            color: darkmode ? "#d6d6d6" : "black",
          }}
        >
          {Checkout(score).map((nr, i) => {
            if (i === 3) {
              return <h2 className="rest">{nr}</h2>;
            } else {
              return <h2>{nr}</h2>;
            }
          })}
        </StyledCheckout>
      )}
    </>
  );
};

const StyledCheckout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 12rem;
  max-width: 12rem;
  overflow: hidden;
  height: 2rem;
  padding: 0.25rem;
  animation: checkoutBannerIn 0.2s forwards;
  white-space: nowrap;
  margin-bottom: 0.5rem;
  @media (max-width: 45rem) {
    width: 5rem;
    max-width: 10rem;
    height: 1.4rem;
    max-height: 1.4rem;
    margin-top: -0.2rem;
  }
  @keyframes checkoutBannerIn {
    from {
      height: 0rem;
    }
    to {
      height: 2rem;
    }
  }
  .rest {
    opacity: 0.5;
  }
`;

export const InputOutput = ({
  title,
  input,
  align,
  dropdown,
  placeholder,
  state,
  setState,
  width,
  darkmode,
  darker,
  big,
}) => {
  const [menu, setMenu] = useState(false);
  const dropdownHandler = (e) => {
    setMenu(false);
    if (e.target.className !== "noplayers") {
      setState(e.target.innerText);
    }
  };

  const darkercolor = "#b4b4b4";

  return (
    <StyledInputOutput
      style={{
        pointerEvents: input ? "all" : "none",
        justifyContent: align === "center" ? "center" : align === "right" ? "flex-end" : "flex-start",
        width: width,
        height: darker ? (big ? "4rem" : "3rem") : big ? "4.5rem" : "3.5rem",
      }}
      onMouseLeave={() => {
        setMenu(false);
      }}
    >
      <span
        className="span"
        style={{
          background: darkmode ? "var(--main-dark-color)" : "var(--main-light-color)",
          color: darkmode ? (darker ? darkercolor : "white") : darker ? darkercolor : "black",
          fontSize: big ? "1rem" : "0.75rem",
        }}
      >
        {title}
      </span>
      <input
        className="input"
        type="text"
        placeholder={placeholder}
        value={state}
        onChange={(e) => {
          setState(e.target.value);
        }}
        onClick={() => {
          setMenu(false);
        }}
        style={{
          textAlign: align === "center" ? "center" : align === "right" ? "right" : "left",
          padding: dropdown ? "0rem 4.6rem 0rem 0.65rem" : "0rem 0.65rem",
          border: darkmode
            ? darker
              ? "2px solid" + darkercolor
              : "2px solid white"
            : darker
            ? "2px solid" + darkercolor
            : "2px solid black",
          color: darkmode ? (darker ? darkercolor : "white") : darker ? darkercolor : "black",
          borderRadius: menu ? "8px 8px 0 0" : "8px",
          fontSize: big ? "1.8rem" : "1rem",
        }}
      />
      {dropdown && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="12"
          viewBox="0 0 19 12"
          onClick={() => {
            setMenu(!menu);
          }}
        >
          <path
            id="Vereinigungsmenge_14"
            data-name="Vereinigungsmenge 14"
            d="M0-1718l10,8Zm10-9-10,9Z"
            transform={menu ? "translate(-1709 1) rotate(90)" : "translate(1728 11) rotate(-90)"}
            fill="none"
            stroke={darkmode ? (darker ? "#747474" : "#fff") : darker ? "#747474" : "#000"}
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      )}

      {menu && !darkmode && (
        <Dropdown onClick={dropdownHandler}>
          {dropdown?.length === 0 && <div className="noplayers">Keine Spieler gespeichert</div>}
          {dropdown &&
            dropdown.map((drop) => {
              return <div>{drop}</div>;
            })}
        </Dropdown>
      )}
      {menu && darkmode && (
        <DropdownDark
          onClick={dropdownHandler}
          onMouseLeave={() => {
            setMenu(false);
          }}
        >
          {dropdown?.length === 0 && <div className="noplayers">Keine Spieler gespeichert</div>}
          {dropdown &&
            dropdown.map((drop) => {
              return <div>{drop}</div>;
            })}
        </DropdownDark>
      )}
    </StyledInputOutput>
  );
};

const Dropdown = styled.div`
  position: absolute;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: white;
  left: 0;
  top: 3.4rem;
  border: 2px solid black;
  border-radius: 0 0 8px 8px;
  z-index: 100;
  div {
    cursor: pointer;
    padding: 0.5rem 0.7rem;
    background: white;
    transition: background 0.1s ease;
    font-size: 1.1rem;
    &:hover {
      background: #f0f0f0;
    }
    &:active {
      background: #cacaca;
    }
  }
  max-height: 10.5rem;
  overflow-y: scroll;
`;

const DropdownDark = styled.div`
  position: absolute;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: #1a1a1a;
  left: 0;
  top: 3.4rem;
  border: 2px solid white;
  border-radius: 0 0 8px 8px;
  z-index: 100;
  color: white;
  div {
    cursor: pointer;
    padding: 0.5rem 0.7rem;
    background: #1a1a1a;
    transition: background 0.1s ease;
    font-size: 1.1rem;
    color: white;
    &:hover {
      background: #1d1d1d;
    }
    &:active {
      background: #383838;
    }
  }
  max-height: 10.5rem;
  overflow-y: scroll;
`;

const StyledInputOutput = styled.div`
  position: relative;
  display: flex;
  padding: 0rem 0.5rem;
  margin: 0 0 1rem 0;
  svg {
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-35%);
    cursor: pointer;
    z-index: 50;
    height: 2rem;
    width: 2rem;
    padding: 0.5rem;
  }
  .input {
    width: 100%;
    height: calc(100% - 0.5rem);
    position: absolute;
    bottom: 0;
    left: 0;
    background: none;
    font-size: 1.2rem;
    user-select: text;
    @media (max-width: 40rem) {
      font-size: 1.1rem;
    }
  }
  .span {
    z-index: 1;
    font-weight: 600;
    font-size: 0.75rem;
    background: white;
    height: 1rem;
    padding: 0rem 0.25rem;
    white-space: nowrap;
  }
`;

export const CheckSelector = ({
  label,
  setState,
  state,
  darkmode,
  setInfluencer,
  marginR = "0rem",
  marginL = "0rem",
}) => {
  const thischeckbox = useRef(null);

  useEffect(() => {
    thischeckbox.current.checked = state;
  }, [state, thischeckbox]);

  const checkHandler = () => {
    setState && setState(true);
    setInfluencer && setInfluencer(false);
  };

  return (
    <StyledCheckbox
      style={{
        color: darkmode ? "white" : "black",
        marginLeft: marginL,
        marginRight: marginR,
      }}
    >
      <div
        style={{
          border: darkmode
            ? state
              ? "2px solid var(--main-blue-color)"
              : "2px solid white"
            : state
            ? "2px solid var(--main-blue-color)"
            : "2px solid black",
          background: state ? "var(--main-blue-color)" : "none",
        }}
        onClick={checkHandler}
        ref={thischeckbox}
        type="checkbox"
      />
      <span>{label}</span>
    </StyledCheckbox>
  );
};

export const SingleCheckSelector = ({ label, setState, state, darkmode, marginR = "0rem", marginL = "0rem" }) => {
  const thischeckbox = useRef(null);

  useEffect(() => {
    thischeckbox.current.checked = state;
  }, [state, thischeckbox]);

  const checkHandler = () => {
    setState && setState(!state);
  };

  return (
    <StyledCheckbox
      style={{
        color: darkmode ? "white" : "black",
        marginLeft: marginL,
        marginRight: marginR,
      }}
    >
      <div
        style={{
          border: darkmode
            ? state
              ? "2px solid var(--main-blue-color)"
              : "2px solid white"
            : state
            ? "2px solid var(--main-blue-color)"
            : "2px solid black",
          background: state ? "var(--main-blue-color)" : "none",
        }}
        onClick={checkHandler}
        ref={thischeckbox}
        type="checkbox"
      />
      <span>{label}</span>
    </StyledCheckbox>
  );
};

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin: 0.1rem;
  div {
    width: 1.6rem;
    height: 1.6rem;
    min-width: 1.6rem;
    min-height: 1.6rem;
    cursor: pointer;
    background: none;
    border-radius: 5px;
    margin-right: 0.8rem;
  }
  span {
    max-width: calc(100% - 4rem);
  }
`;

export const Selector = ({ input, align, placeholder, state, setState, width, after, options, darkmode }) => {
  let start = options.indexOf(state);

  const selectHandler = (direction) => {
    if (direction === "next") {
      if (start + 1 === options.length) {
        setState(options[options.length - 1]);
      } else {
        setState(options[start + 1]);
      }
    } else if (direction === "back") {
      if (start === 0) {
        setState(options[0]);
      } else {
        setState(options[start - 1]);
      }
    }
  };

  return (
    <StyledSelector
      style={{
        justifyContent: align === "center" ? "center" : align === "right" ? "flex-end" : "flex-start",
        width: width,
      }}
    >
      <input
        type="text"
        placeholder={placeholder}
        value={state + after}
        onChange={(e) => {
          setState(e.target.value);
        }}
        style={{
          textAlign: align === "center" ? "center" : align === "right" ? "right" : "left",
          padding: "0rem 0.65rem",
          border: darkmode ? "2px solid #fff" : "2px solid #000",
          color: darkmode ? "#fff" : "#000",
        }}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="19"
        viewBox="0 0 12 19"
        className="bwds"
        onClick={() => {
          selectHandler("back");
        }}
        style={{ opacity: start !== 0 ? 1 : 0 }}
      >
        <path
          id="Vereinigungsmenge_5"
          data-name="Vereinigungsmenge 5"
          d="M0-1718l10,8Zm10-9-10,9Z"
          transform="translate(1 1728)"
          fill="none"
          stroke={darkmode ? "#fff" : "#000"}
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="19"
        viewBox="0 0 12 19"
        className="fwds"
        style={{ opacity: start + 1 !== options.length ? 1 : 0 }}
        onClick={() => {
          selectHandler("next");
        }}
      >
        <path
          id="Vereinigungsmenge_4"
          data-name="Vereinigungsmenge 4"
          d="M0-1718l10,8Zm10-9-10,9Z"
          transform="translate(11 -1709) rotate(180)"
          fill="none"
          stroke={darkmode ? "#fff" : "#000"}
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    </StyledSelector>
  );
};

const StyledSelector = styled.div`
  height: 3.5rem;
  position: relative;
  display: flex;
  padding: 0rem 0.5rem;
  margin-bottom: 1rem;
  .bwds,
  .fwds {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-38%);
    cursor: pointer;
    z-index: 50;
    height: 2rem;
    width: 2rem;
    padding: 0.5rem;
    transition: opacity 0.1s ease;
  }
  .bwds {
    left: 1rem;
  }
  input {
    width: 100%;
    height: calc(100% - 0.5rem);
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 8px;

    font-size: 1.2rem;
    user-select: text;
    pointer-events: none;
    background: none;
  }
  span {
    z-index: 1;
    font-weight: 600;
    font-size: 0.75rem;
    height: 1rem;
    padding: 0rem 0.25rem;
  }
`;
