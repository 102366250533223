const timer = (action, setTimer) => {
  let millisecond = 0;
  let timer;

  function timeStart() {
    clearInterval(timer);
    timer = setInterval(() => {
      millisecond += 100;
      let dateTimer = new Date(millisecond);

      setTimer(
        ("0" + dateTimer.getUTCHours()).slice(-2) +
          ":" +
          ("0" + dateTimer.getUTCMinutes()).slice(-2)
      );
    }, 100);
  }

  function timePaused() {
    clearInterval(timer);
  }

  function timeReset() {
    setInterval(timer);
    millisecond = 0;
    setTimer("00:00:00");
  }

  switch (action) {
    case "start":
      timeStart();
      break;
    case "pause":
      timePaused();
      break;
    case "reset":
      timeReset();
      break;
    default:
      timeStart();
      break;
  }
};

export default timer;
