import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { InputOutput } from "../components/GuiElements";
import { Line } from "react-chartjs-2";

const Statistic = ({
  setCurrentPlayer,
  currentPlayer,
  setCurrentGame,
  currentGame,
  setGameSettings,
  gameSettings,
  setSettings,
  settings,
  allPlayers,
  setAllPlayers,
  spielstand,
  setSpielstand,
  gesamtdauer,
  statistics,
}) => {
  const history = useHistory();
  const [allPlayerData, setAllPlayerData] = useState([]);
  const spielmode = gameSettings.spielmodus === 2;
  const inputWidth = "calc(50% - 0.8rem)";
  const inputWidthPerson = "calc(50% - 0.8rem)";

  useEffect(() => {
    if (!statistics || !allPlayers) {
      history.push("/");
    }
    let playerData = [];
    let playerPlaces = [];
    allPlayers?.forEach((player, i) => {
      playerPlaces?.push({
        name: player.name,
        index: i,
        score: spielstand[i],
      });
      let place;
      let wonLegs;
      let wonSets = spielstand[i];
      let lostLegs;
      let oneDartAvg; //
      let threeDartAvg; //
      let throws; //
      let accuracy;
      let over60 = 0; //
      let over80 = 0; //
      let over100 = 0; //
      let over120 = 0; //
      let over140 = 0; //
      let over180 = 0; //
      let doublequote;
      let highFinishes;
      let highestFinish;
      let highestFinishAccuracy;

      // THREE DART AVERAGES, ONE DART AVGS AND THROW AMOUNT
      let playerScores = [];
      let playerScoresSingle = [];

      player?.game?.forEach((set) => {
        set.forEach((leg) => {
          let legscore = 0;
          let lasttype = 1;
          leg.forEach((score, i) => {
            let threeDartScore = 0;
            score.forEach((single) => {
              lasttype = 1;
              if (single.includes("D")) {
                playerScoresSingle.push(2 * parseInt(single.replace("D", "")));
                threeDartScore =
                  threeDartScore + 2 * parseInt(single.replace("D", ""));
                lasttype = 2;
              } else if (single.includes("T")) {
                playerScoresSingle.push(3 * parseInt(single.replace("T", "")));
                threeDartScore =
                  threeDartScore + 3 * parseInt(single.replace("T", ""));
                lasttype = 3;
              } else if (single === "BULL") {
                playerScoresSingle.push(25);
                threeDartScore = threeDartScore + 25;
                lasttype = 1;
              } else if (single === "BULL'S EYE") {
                playerScoresSingle.push(50);
                threeDartScore = threeDartScore + 50;
                lasttype = 2;
              } else if (single === "-") {
                playerScoresSingle.push(0);
                threeDartScore = threeDartScore + 50;
                lasttype = 0;
              } else {
                playerScoresSingle.push(parseInt(single));
                threeDartScore = threeDartScore + parseInt(single);
                lasttype = 1;
              }
            });
            playerScores.push(threeDartScore);

            // ARCHIEVEMENTS

            if (threeDartScore >= 60 && threeDartScore < 80) {
              over60 = over60 + 1;
            } else if (threeDartScore >= 80 && threeDartScore < 100) {
              over80 = over80 + 1;
            } else if (threeDartScore >= 100 && threeDartScore < 120) {
              over100 = over100 + 1;
            } else if (threeDartScore >= 120 && threeDartScore < 140) {
              over120 = over120 + 1;
            } else if (threeDartScore >= 140 && threeDartScore < 180) {
              over140 = over140 + 1;
            } else if (threeDartScore >= 180) {
              over180 = over180 + 1;
            }

            // CHECK IF VALID
            if (
              gameSettings.startzahl - legscore - threeDartScore === 0 &&
              lasttype !== 2
            ) {
              // GENAU AUF NULL ABER SINGLE OUT
            } else if (
              gameSettings.startzahl - legscore - threeDartScore ===
              1
            ) {
              // AUF 1
            } else if (gameSettings.startzahl - legscore - threeDartScore < 0) {
              // UEBERWERFEN
            } else {
              legscore = legscore + threeDartScore;
            }

            if (gameSettings.startzahl - legscore - threeDartScore > 0) {
              legscore = legscore + threeDartScore;
            }
          });
        });
      });

      // THREE DART AVERAGE OVER TIME
      threeDartAvg =
        playerScores.reduce((a, b) => a + b, 0) / playerScores.length || 0;
      oneDartAvg =
        playerScoresSingle.reduce((a, b) => a + b, 0) /
          playerScoresSingle.length || 0;
      throws = playerScoresSingle.length;

      playerData.push({
        name: player.name,
        start: i,
        place: place,
        wonLegs: wonLegs,
        wonSets: wonSets,
        lostLegs: lostLegs,
        oneDartAvg: oneDartAvg,
        threeDartAvg: threeDartAvg,
        throws: throws,
        accuracy: accuracy,
        over60: over60,
        over80: over80,
        over100: over100,
        over120: over120,
        over140: over140,
        over180: over180,
        doublequote: doublequote,
        highFinishes: highFinishes,
        highestFinish: highestFinish,
        highestFinishAccuracy: highestFinishAccuracy,
        scores: playerScores,
      });
    });
    playerPlaces = playerPlaces.sort((a, b) => (a.score > b.score ? 1 : -1));
    playerPlaces.forEach((player, i) => {
      playerData[player.index].place = i + 1;
    });
    setAllPlayerData(playerData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  localStorage.setItem("lastgame", JSON.stringify(allPlayers));

  const getLabels = (array) => {
    const labels = [];
    for (let i = 1; i < array.length; ++i) {
      labels.push("");
    }
    return labels;
  };
  return (
    <Card
      style={{
        background: !settings.darkmode
          ? "var(--main-dark-color)"
          : "var(--main-light-color)",
      }}
    >
      <header>
        <h2
          onClick={() => {
            history.push("/winner");
          }}
          style={{ color: !settings.darkmode ? "#fff" : "#000" }}
        >
          <span className="back">{"<"}&nbsp;&nbsp;&nbsp;</span>
          <svg
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fill-rule="evenodd"
            clip-rule="evenodd"
          >
            <path
              fill={!settings.darkmode ? "#fff" : "#000"}
              d="M18.799 7.038c-.496-.535-.799-1.252-.799-2.038 0-1.656 1.344-3 3-3s3 1.344 3 3-1.344 3-3 3c-.146 0-.29-.01-.431-.031l-3.333 6.032c.475.53.764 1.231.764 1.999 0 1.656-1.344 3-3 3s-3-1.344-3-3c0-.583.167-1.127.455-1.587l-2.565-3.547c-.281.087-.58.134-.89.134l-.368-.022-3.355 6.069c.451.525.723 1.208.723 1.953 0 1.656-1.344 3-3 3s-3-1.344-3-3 1.344-3 3-3c.186 0 .367.017.543.049l3.298-5.967c-.52-.539-.841-1.273-.841-2.082 0-1.656 1.344-3 3-3s3 1.344 3 3c0 .617-.187 1.191-.507 1.669l2.527 3.495c.307-.106.637-.164.98-.164.164 0 .325.013.482.039l3.317-6.001zm-3.799 7.962c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-6-8c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
            />
          </svg>
          Statistiken
        </h2>
      </header>
      <div className="stat">
        <h2
          className="title"
          style={{ color: !settings.darkmode ? "white" : "black" }}
        >
          Allgemein
        </h2>
        <div className="info">
          <InputOutput
            title={`Spielstand`}
            align={"center"}
            width={inputWidth}
            placeholder={"Spielstand"}
            state={spielstand.join(":")}
            setState={setSpielstand}
            key={"info-spielstand"}
            darkmode={!settings.darkmode}
          />
          <InputOutput
            title={`Dauer`}
            align={"center"}
            width={inputWidth}
            placeholder={"Dauer"}
            state={gesamtdauer}
            setState={setSpielstand}
            key={"info-dauer"}
            darkmode={!settings.darkmode}
          />
        </div>
      </div>
      <div className="stat">
        <h2
          className="title"
          style={{ color: !settings.darkmode ? "white" : "black" }}
        >
          Infos zum Spiel
        </h2>
        <div className="info">
          <InputOutput
            title={"Würfe"}
            align={"center"}
            width={inputWidth}
            placeholder={`Würfe`}
            state={allPlayerData.reduce(function (total, currentValue) {
              return total + currentValue.throws;
            }, 0)}
            setState={setAllPlayerData}
            key={"1"}
            darkmode={!settings.darkmode}
          />
          <InputOutput
            title={"60+"}
            align={"center"}
            width={inputWidth}
            placeholder={`60+`}
            state={allPlayerData.reduce(function (total, currentValue) {
              return total + currentValue.over60;
            }, 0)}
            setState={setAllPlayerData}
            key={"1"}
            darkmode={!settings.darkmode}
          />
          <InputOutput
            title={"80+"}
            align={"center"}
            width={inputWidth}
            placeholder={`80+`}
            state={allPlayerData.reduce(function (total, currentValue) {
              return total + currentValue.over80;
            }, 0)}
            setState={setAllPlayerData}
            key={"1"}
            darkmode={!settings.darkmode}
          />
          <InputOutput
            title={"100+"}
            align={"center"}
            width={inputWidth}
            placeholder={`100+`}
            state={allPlayerData.reduce(function (total, currentValue) {
              return total + currentValue.over100;
            }, 0)}
            setState={setAllPlayerData}
            key={"1"}
            darkmode={!settings.darkmode}
          />
          <InputOutput
            title={"120+"}
            align={"center"}
            width={inputWidth}
            placeholder={`120+`}
            state={allPlayerData.reduce(function (total, currentValue) {
              return total + currentValue.over120;
            }, 0)}
            setState={setAllPlayerData}
            key={"1"}
            darkmode={!settings.darkmode}
          />
          <InputOutput
            title={"140+"}
            align={"center"}
            width={inputWidth}
            placeholder={`140+`}
            state={allPlayerData.reduce(function (total, currentValue) {
              return total + currentValue.over140;
            }, 0)}
            setState={setAllPlayerData}
            key={"1"}
            darkmode={!settings.darkmode}
          />
          <InputOutput
            title={"180"}
            align={"center"}
            width={inputWidth}
            placeholder={`180`}
            state={allPlayerData.reduce(function (total, currentValue) {
              return total + currentValue.over180;
            }, 0)}
            setState={setAllPlayerData}
            key={"1"}
            darkmode={!settings.darkmode}
          />
        </div>
      </div>
      <div className="stat">
        <h2
          className="title"
          style={{ color: !settings.darkmode ? "white" : "black" }}
        >
          Spielerstatistiken
        </h2>
        {allPlayerData
          ?.sort((a, b) => (a.start > b.start ? 1 : -1))
          ?.map((player, index) => {
            return (
              <div
                style={{
                  border: !settings.darkmode
                    ? "2px solid white"
                    : "2px solid black",
                }}
                className="wrapper"
                key={player.name + index}
              >
                <span
                  style={{
                    color: !settings.darkmode ? "white" : "black",
                    background: !settings.darkmode
                      ? "var(--main-dark-color)"
                      : "var(--main-light-color)",
                  }}
                  className="playername"
                >
                  {player.name}
                </span>
                <h2
                  className="title"
                  style={{ color: !settings.darkmode ? "white" : "black" }}
                >
                  Allgemein
                </h2>
                <div className="info">
                  {gameSettings.spielmodus === 2 && (
                    <InputOutput
                      title={"Gewonnene Sets"}
                      align={"center"}
                      width={inputWidthPerson}
                      placeholder={`Gewonnen`}
                      state={player.wonSets}
                      setState={setAllPlayerData}
                      key={player.name + index + "sets"}
                      darkmode={!settings.darkmode}
                    />
                  )}
                  {gameSettings.spielmodus === 2 && (
                    <InputOutput
                      title={"Verlorene Sets"}
                      align={"center"}
                      width={inputWidthPerson}
                      placeholder={`Verloren`}
                      state={
                        spielstand.reduce((a, b) => a + b, 0) - player.wonSets
                      }
                      setState={setAllPlayerData}
                      key={player.name + index + "lostsets"}
                      darkmode={!settings.darkmode}
                    />
                  )}

                  <InputOutput
                    title={"Würfe"}
                    align={"center"}
                    width={inputWidthPerson}
                    placeholder={`Würfe`}
                    state={statistics?.[index].throws}
                    setState={setAllPlayerData}
                    key={player.name + index + "throws"}
                    darkmode={!settings.darkmode}
                  />

                  <InputOutput
                    title={"Gewonnene Legs"}
                    align={"center"}
                    width={inputWidthPerson}
                    placeholder={`Legs`}
                    state={statistics?.[index].wonLegs}
                    setState={setAllPlayerData}
                    key={player.name + index + "legs"}
                    darkmode={!settings.darkmode}
                  />

                  <InputOutput
                    title={"Verlorene Legs"}
                    align={"center"}
                    width={inputWidthPerson}
                    placeholder={`Legs`}
                    state={statistics?.[index]?.lostLegs}
                    setState={setAllPlayerData}
                    key={player.name + index + "lostlegs"}
                    darkmode={!settings.darkmode}
                  />

                  <InputOutput
                    title={"Ø 3 Dart"}
                    align={"center"}
                    width={inputWidthPerson}
                    placeholder={`Ø 3 Dart`}
                    state={Math.round(player.threeDartAvg * 100) / 100}
                    setState={setAllPlayerData}
                    key={player.name + index + "3dartavg"}
                    darkmode={!settings.darkmode}
                  />
                  <InputOutput
                    title={"Ø 1 Dart"}
                    align={"center"}
                    width={inputWidthPerson}
                    placeholder={`Ø 1 Dart`}
                    state={Math.round((player.oneDartAvg * 100) / 100)}
                    setState={setAllPlayerData}
                    key={player.name + index + "1dartavg"}
                    darkmode={!settings.darkmode}
                  />
                  <InputOutput
                    title={"60+"}
                    align={"center"}
                    width={inputWidthPerson}
                    placeholder={`60+`}
                    state={player.over60}
                    setState={setAllPlayerData}
                    key={player.name + index + "60+"}
                    darkmode={!settings.darkmode}
                  />
                  <InputOutput
                    title={"80+"}
                    align={"center"}
                    width={inputWidthPerson}
                    placeholder={`80+`}
                    state={player.over80}
                    setState={setAllPlayerData}
                    key={player.name + index + "80+"}
                    darkmode={!settings.darkmode}
                  />
                  <InputOutput
                    title={"100+"}
                    align={"center"}
                    width={inputWidthPerson}
                    placeholder={`100+`}
                    state={player.over100}
                    setState={setAllPlayerData}
                    key={player.name + index + "100+"}
                    darkmode={!settings.darkmode}
                  />
                  <InputOutput
                    title={"120+"}
                    align={"center"}
                    width={inputWidthPerson}
                    placeholder={`120+`}
                    state={player.over120}
                    setState={setAllPlayerData}
                    key={player.name + index + "120+"}
                    darkmode={!settings.darkmode}
                  />
                  <InputOutput
                    title={"140+"}
                    align={"center"}
                    width={inputWidthPerson}
                    placeholder={`140+`}
                    state={player.over140}
                    setState={setAllPlayerData}
                    key={player.name + index + "140+"}
                    darkmode={!settings.darkmode}
                  />
                  <InputOutput
                    title={"180"}
                    align={"center"}
                    width={inputWidthPerson}
                    placeholder={`180`}
                    state={player.over180}
                    setState={setAllPlayerData}
                    key={player.name + index + "180+"}
                    darkmode={!settings.darkmode}
                  />
                </div>
                {player?.scores?.length > 1 && (
                  <h2
                    className="title avg"
                    style={{ color: !settings.darkmode ? "white" : "black" }}
                  >
                    Score Verlauf
                  </h2>
                )}
                {player?.scores?.length > 1 && (
                  <Line
                    height="40vw"
                    width="100%"
                    data={{
                      labels: getLabels(player?.scores),
                      datasets: [
                        {
                          label: "Average",
                          backgroundColor: "#2665e2",
                          borderColor: "#2665e2",
                          data: player?.scores,
                          tension: 0.3,
                        },
                      ],
                    }}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                        tooltip: {
                          enabled: false,
                        },
                      },
                    }}
                  />
                )}
              </div>
            );
          })}
      </div>
    </Card>
  );
};

const Card = styled.div`
  width: 100%;
  min-height: 100vh;
  min-width: 18rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  animation: pageSlideInTransition 0.5s forwards;
  .avg {
    margin-top: 1rem;
  }
  .stat {
    width: 100%;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1.4rem;
    .wrapper {
      width: 100%;
      padding: 2rem 2rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 1.4rem;
      border: 2px solid white;
      border-radius: 0.5rem;
      min-height: 5rem;
      position: relative;
      margin-top: 0.5rem;
      .playername {
        position: absolute;
        top: -1rem;
        left: 1rem;
        font-size: 1.2rem;
        padding: 0 0.5rem;
      }
    }
    .title {
      margin-bottom: 0.8rem;
    }
    .info {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      div {
        margin-right: 0.8rem !important;
        max-width: 12rem;
      }
    }
  }
  h2 {
    font-size: 1.4rem;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.8rem;
    }
  }
  header {
    width: 100%;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    margin-bottom: 2rem;
    button {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 1rem;
      padding: 0 1rem;
      height: 50%;
    }
  }
`;

export default Statistic;
