import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { SingleCheckSelector, CheckSelector, Selector } from "../components/GuiElements";
import Checkout from "../util/Checkouts";

const Players = ({ settings, setSettings }) => {
  const history = useHistory();
  const [alignment, setAlignment] = useState(
    Object.keys(settings)?.includes("alignment") ? (settings?.alignment ? true : false) : true
  );
  const [inverseAlignment, setInverseAlignment] = useState(
    Object.keys(settings)?.includes("alignment") ? (settings?.alignment ? false : true) : false
  );
  const [correction, setCorrection] = useState(
    Object.keys(settings)?.includes("correction") ? (settings?.correction ? true : false) : true
  );
  const [playOn, setPlayOn] = useState(
    Object.keys(settings)?.includes("playOn") ? (settings?.playOn ? true : false) : true
  );

  useEffect(() => {
    setSettings({
      ...settings,
      playOn,
      correction,
      alignment,
    });
  }, [correction, playOn, alignment]);

  return (
    <Card
      style={{
        background: !settings.darkmode ? "var(--main-dark-color)" : "var(--main-light-color)",
      }}
    >
      <header>
        <h2
          onClick={() => {
            history.push("/");
          }}
          style={{ color: !settings.darkmode ? "#fff" : "#000" }}
        >
          <span className="back">{"<"}&nbsp;&nbsp;&nbsp;</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              fill={!settings.darkmode ? "#fff" : "#000"}
              d="M24 14.187v-4.374c-2.148-.766-2.726-.802-3.027-1.529-.303-.729.083-1.169 1.059-3.223l-3.093-3.093c-2.026.963-2.488 1.364-3.224 1.059-.727-.302-.768-.889-1.527-3.027h-4.375c-.764 2.144-.8 2.725-1.529 3.027-.752.313-1.203-.1-3.223-1.059l-3.093 3.093c.977 2.055 1.362 2.493 1.059 3.224-.302.727-.881.764-3.027 1.528v4.375c2.139.76 2.725.8 3.027 1.528.304.734-.081 1.167-1.059 3.223l3.093 3.093c1.999-.95 2.47-1.373 3.223-1.059.728.302.764.88 1.529 3.027h4.374c.758-2.131.799-2.723 1.537-3.031.745-.308 1.186.099 3.215 1.062l3.093-3.093c-.975-2.05-1.362-2.492-1.059-3.223.3-.726.88-.763 3.027-1.528zm-4.875.764c-.577 1.394-.068 2.458.488 3.578l-1.084 1.084c-1.093-.543-2.161-1.076-3.573-.49-1.396.581-1.79 1.693-2.188 2.877h-1.534c-.398-1.185-.791-2.297-2.183-2.875-1.419-.588-2.507-.045-3.579.488l-1.083-1.084c.557-1.118 1.066-2.18.487-3.58-.579-1.391-1.691-1.784-2.876-2.182v-1.533c1.185-.398 2.297-.791 2.875-2.184.578-1.394.068-2.459-.488-3.579l1.084-1.084c1.082.538 2.162 1.077 3.58.488 1.392-.577 1.785-1.69 2.183-2.875h1.534c.398 1.185.792 2.297 2.184 2.875 1.419.588 2.506.045 3.579-.488l1.084 1.084c-.556 1.121-1.065 2.187-.488 3.58.577 1.391 1.689 1.784 2.875 2.183v1.534c-1.188.398-2.302.791-2.877 2.183zm-7.125-5.951c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zm0-2c-2.762 0-5 2.238-5 5s2.238 5 5 5 5-2.238 5-5-2.238-5-5-5z"
            />
          </svg>
          Einstellungen
        </h2>
      </header>
      <div className="settings" style={{ color: !settings.darkmode ? "#fff" : "#000" }}>
        <h4 className="heading">Tastatur Ausrichtung</h4>
        <div className="check-setting">
          <CheckSelector
            label={"Leiste oben"}
            setState={setAlignment}
            state={alignment}
            setInfluencer={setInverseAlignment}
            marginR={"1rem"}
            darkmode={!settings.darkmode}
          />
          <CheckSelector
            label={"Leiste unten"}
            setState={setInverseAlignment}
            state={inverseAlignment}
            setInfluencer={setAlignment}
            darkmode={!settings.darkmode}
          />
        </div>
        <h4 className="heading">Triple/Doppel bei Korrektur automatisch anwenden</h4>
        <div className="check-setting">
          <SingleCheckSelector
            label={"Aktivieren"}
            setState={setCorrection}
            state={correction}
            marginR={"1rem"}
            darkmode={!settings.darkmode}
          />
        </div>
        <h4 className="heading">Bei mehr als 2 Spielern, alle Spieler fertig spielen lassen</h4>
        <div className="check-setting">
          <SingleCheckSelector
            label={"Aktivieren"}
            setState={setPlayOn}
            state={playOn}
            marginR={"1rem"}
            darkmode={!settings.darkmode}
          />
        </div>
      </div>
    </Card>
  );
};

const Card = styled.div`
  width: 100%;
  min-height: 100vh;
  min-width: 18rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
  padding: 0 15% 8rem 15%;
  transition: padding 0.5s ease;
  animation: pageSlideInTransition 0.25s forwards;
  @media (max-width: 50rem) {
    padding: 0;
    padding-bottom: 8rem;
  }
  .info {
    margin: 0.5rem 0 2rem 0;
  }
  .overflow {
    width: calc(100% + 1rem);
    overflow-x: auto;
  }
  .editcheckout {
    margin: 0.8rem 0;
    display: flex;
    align-items: center;
    min-width: 38rem;

    h2 {
      margin-right: 2rem;
      color: var(--main-blue-color);
      width: 2rem;
      cursor: auto;
    }
    div {
      width: 7rem;
      height: 3rem;
      font-size: 1.2rem;
      user-select: none;
      text-align: center;
      background: transparent !important;
      border-radius: 0.5rem;
      margin-right: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
    }
    p {
      cursor: pointer;
      color: var(--main-red-color);
      margin-left: 0.5rem;
    }
  }
  .settings {
    padding: 2rem 1rem 0 1rem;
    h4 {
      font-size: 1.3rem;
      font-weight: 600;
    }
    .heading {
      font-weight: 700;
    }
  }
  .check-setting {
    display: flex;
    width: 100%;
    margin: 1rem 0 3rem 0;
    flex-wrap: wrap;
    div {
      white-space: nowrap;
    }
  }
  h2 {
    font-size: 1.4rem;
    display: flex;
    cursor: pointer;
    align-items: center;
    white-space: nowrap;
    svg {
      width: 2rem;
      height: 2rem;
      margin-right: 0.8rem;
    }
  }
  header {
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem 0 1rem;
    button {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 1rem;
      padding: 0 1rem;
      height: 50%;
    }
  }
`;

export default Players;
